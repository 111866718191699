.layout__container {
    min-height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.homePage__container {
    padding: 0;
    background-origin: 1px solid black;
}
