@media only screen and (max-width: 600px) {

    .common-container {
        padding: 5px;
       
        background: grey;
        width: 98%;
        margin: auto;
        margin-top: 5px;
    }
    .common-container .container-fluid {
        padding:0px;
    }
    .responsive-crousel-slider-img{
        height: initial!important;
    }
    
}
