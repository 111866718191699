.filter-section {
  position: sticky;
  top: -700px;
  margin-top: -10%;
}
.filter-section h6 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #424242;
}
.filter-section span {
    font-size: 14px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #424242;
}