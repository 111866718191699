.home-suggetion {
    min-height: 500px;
    width: 100%;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    padding: 5px;
  }
  .city-name {
    margin: 10px 5px;
  }
  .hostle-pg-txt {
    font-weight: 700;
    font-family: sans-serif;
    color: darkblue;
    padding: 10px;
  }
  
  .media-img {
    height: 240px;
    overflow: hidden;
    cursor: pointer;
  }
  .media-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .hostel-cards-col {
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16); */
    padding: 8px;
    margin: 0;
    transition: 0.6s;
    cursor: pointer;
    box-shadow: 0 0 5px 0 #15124b inset;
    border-radius: 5px;
  }
  button.book-now-home-btn {
    float: right;
    padding: 4px 6px;
    background-color: #15124b;
    color: white;
    border-radius: 5px;
    position: relative;
    bottom: 5px;
    font-size: 14px;
    font-family: sans-serif;
  }
  
  .hostel-cards-col:hover {
    -webkit-transform: scale(1.05) !important;
    transform: scale(1.05) !important;
    transition: 0.6s;
  }
  .hostel-details {
    padding-right: 5px;
    padding-left: 5px;
  }
  
  .hostel-details h3 {
    font-size: 16px;
    margin-top: 5px;
    font-weight: 700;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    cursor: pointer;
    color: black;
    text-align: left;
  }
  .hostel-details h6 {
    font-size: 12px;
  
    display: flex;
    justify-content: space-between;
  }
  span.home-lockation-txt {
    width: 85% !important;
    color: #121e52;
    text-align: left;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    top: -2px;
    display: -webkit-box;
    position: relative;
    font-weight: 700;
  }
  
  span.home-rating {
    width: 15% !important;
    background: #15124b;
    color: white;
    padding: 3px 0px;
    border-radius: 2px;
    position: relative;
    bottom: 5px;
  }
  span.home-rating-star svg {
    position: relative;
    left: 2px;
    bottom: 1px;
    font-size: 16px;
  }
  span.rating-point {
    font-size: 13px;
  }
  .hostel-facility-details {
    padding-right: 2px;
  }
  ul.facility-text {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px;
    margin: revert !important;
  }
  ul.facility-text li {
    list-style: none;
    font-size: 12px;
    width: 33.33%;
    display: flex;
    align-items: center;
    /* color: #15124b; */
    color: black;
    font-weight: 600;
  }
  span.btn-pluse svg {
    color: #15124b !important;
    font-weight: bold !important;
  }
  ul.facility-text li span svg {
    color: green;
    font-size: 12px;
    position: relative;
    bottom: 1px;
  }
  ul.facility-text li a {
    text-decoration: none;
  
    position: relative;
  
    font-weight: 900;
    left: 35px;
    color: #15124b;
    border: radius;
    top: 2px;
    padding: 3px 8px;
    border-radius: 5px;
  }
  ul.facility-text li a:hover {
    color: green !important;
  }
  .hostel-bed-avility-details {
    padding-right: 0px;
    padding-left: 0px;
  }
  .hostel-bed-avility-details h6 {
    display: flex;
    justify-content: space-between;
  }
  .hostel-bed-avility-details ul {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0px;
  }
  .hostel-bed-avility-details ul li {
    list-style: none;
    /* width: 33.33%; */
    display: flex;
    margin-right: 10px;
  }
  .hostel-bed-avility-details ul li:nth-child(1) {
    width: 28%;
  }
  .hostel-bed-avility-details ul li:nth-child(2) {
    width: 34%;
  }
  .hostel-bed-avility-details ul li:nth-child(3) {
    width: 37%;
  }
  .hostel-bed-avility-details ul li img {
    width: 20px !important;
  }
  span.hostel-bed-price {
    font-size: 12px;
    font-weight: 600;
    position: relative;
    top: 5px;

  }
  span.bed-avl-txt {
    color: #2c354f;
    font-weight: 600;
    font-family: sans-serif;
    width: 50%;
    text-align: left;
  }
  .home-card-rating {
    width: 50%;
  }
  button.owl-prev {
    height: 40px;
    width: 40px;
    border-radius: 50% !important;
  }
  .owl-nav button.owl-prev {
    left: 10px !important;
    /* background-color: #2c354f!important; */
    background-color: white !important;
    margin-top: 40px !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  button.owl-prev span {
    font-size: 38px !important;
    color: #2c354f !important;
    top: -14px !important;
  }
  .owl-nav button {
    top: 46% !important;
  }
  button.owl-next {
    height: 40px;
    width: 40px;
    border-radius: 50% !important;
  }
  .owl-nav button.owl-next {
    right: 10px !important;
    /* background-color: #2c354f!important; */
    margin-top: 40px !important;
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  button.owl-next span {
    font-size: 38px !important;
    position: relative;
    color: #2c354f !important;
    top: -14px !important;
  }
  .ribbon {
    height: 188px;
    position: absolute;
    margin-bottom: 30px;
  
    text-transform: uppercase;
    color: white;
  }
  .ribbon3 {
    width: 100px;
    height: 29px;
    line-height: 29px;
    /* padding-left: 15px; */
    font-size: 12px;
    position: absolute;
    left: -8px;
    top: 8px;
    font-weight: 700;
    background: #15124b;
  }
  .ribbon3:before,
  .ribbon3:after {
    content: "";
    position: absolute;
  }
  .ribbon3:before {
    height: 0;
    width: 0;
    top: -8.5px;
    left: 0.1px;
    border-bottom: 9px solid #15124b;
    border-left: 9px solid transparent;
  }
  
  .ribbon3:after {
    height: 0;
    width: 0;
    right: -14px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #15124b;
  }
  .container-cards.second-row-card {
    margin-top: 20px;
  }