.account-modal {
  background-color: white !important;
}
img.account-modal-logo {
  height: 75px;
}
button.close-button.account-modal-close-button {
  height: 30px;
  width: 30px;
  font-size: 30px;
  line-height: 30px;
  border-radius: 50%;
  background: #2c354f;
  color: white;
  position: absolute;
  right: 10px;
  top: 10px;
}

.modal-header.account-modal-header {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}
.button-row {
  margin-top: 7px;
  margin-bottom: 3px;
  display: grid;
  grid-template-columns: auto auto auto;
}

.button-row button {
  border-radius: 10px;
  border: none;
  width: 100px;
  min-height: 60px;
  font-family: Sans-serif;
  color: #fff;
  margin: 5px;
  box-shadow: 0px 4px 4px #bdbdbd;
  transition: all 0.3s ease-in-out;
}

.button-row button:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
}

.upload {
  font-size: 14px;
  background: linear-gradient(180deg, #b8b50a 0%, #ef4949 100%);
}

.feeling {
  font-size: 14px;
  background: linear-gradient(180deg, #31c300 0%, #ab7811 100%);
}

.life-event {
  font-size: 13.7px;
  background: linear-gradient(180deg, #ff1024 0%, #ffb34a 100%);
}

.people {
  font-size: 13.5px;
  background: linear-gradient(180deg, #0095b7 0%, #5012ba 100%);
}

.location {
  font-size: 14px;
  background: linear-gradient(180deg, #c329c9 0%, #2b5dff 100%);
}

.live-video {
  font-size: 13.8px;
  background: linear-gradient(180deg, #c800ff 0%, #ff076a 51%, #ff6c6c 100%);
}

.post {
  width: 100%;
  padding: 10px;
  margin-top: 7px;
  border: none;
  border-radius: 11px;
  font-size: 18px;
  font-family: Sans-serif;
  color: #fff;
  background-size: 200% 100%;
  background-image: linear-gradient(145deg, #ff00e1, #0000ff, #00d9ff);
  transition: 0.3s ease-out;
}

.post:hover {
  background-position: 99%;
}
p.comn-card-icon svg {
  font-size: 40px;
  position: relative;
  top: 10px;
}
