._hostel-page-desktop-container {
  width: 90%;
  margin: 20px auto;
  /* min-height: 100px; */
  /* background-color: rgb(133, 146, 114); */
}
._hostel-page-sub-sections {
  scroll-margin-top: 200px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  background-color: #fff;
  padding: 20px;
  /* height: 300px; */
  /* border: 2px solid black; */
}
.material-shadow-card-3 {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.18);
}
._block-header {
  border-bottom: 1px solid #e7eff9;
  margin-bottom: 20px;
}
._block-header-text {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  text-align: left;
}
.checkout-page-login-form-container {
  width: 65%;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: auto;
}
._login-through-otp-container {
  padding-bottom: 20px;
  padding-right: 5px;
  padding-left: 5px;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.needs-validation label {
  display: inline-block;
  margin-bottom: 0.5rem;
  text-align: left;
  width: 100%;
  font-weight: 500;
}
.checkout-input-box {
  height: 40px !important;
  font-size: 12px !important;
  color: #333 !important;
  border-radius: 3px !important;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn_full {
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: #fff;
  background: #662d91;
  padding: 12px 20px;
  display: inline-block;
  outline: none;
  font-size: 12px;
  transition: all 0.3s;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.checkout-problem-whatsapp-button {
  width: 95%;
  margin: 40px auto;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  justify-content: space-evenly;
}
.checkout-problem-img-box {
  overflow: hidden;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  /* background-color: #662d91; */
}
.checkout-problem-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}
.checkout-problem-text {
  padding: 5px;
  margin-left: 15px;
  margin-bottom: 0px;
  color: black;
  font-weight: 500;
}
