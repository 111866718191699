a {
  text-decoration: none;
}
.wrapper-owner-packages {
  width: 95%;
  max-width: 1200px;
  margin: 30px auto;
}
.pricing-table h2 {
  font-size: 1.5em;
  padding: 20px 10px;
  margin: 0;
}

.description-owner-card-txt {
  margin-top: 5px;
  padding: 0px 12px;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  color: #15124b;
   
}
.price,
.pricing-box {
  -webkit-transition: all ease-out 0.2s;
  transition: all ease-out 0.2s;
}
.price {
  background: #4a8eb2;
  color: #fff;
  font-size: 2em;
  font-weight: 600;
  position: relative;
  padding: 10px 10px;
  display: block;
}

.pricing-box:hover .price {
  background:#15124b;
  box-shadow: inset 0 0 100px 0 rgba(0, 0, 0, 0.3);
  color: white;
}
.btn-owner-card {
    background-image:linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
  border: 1px solid #15124b;
  color: white;
  -webkit-transition: all ease-out 0.2s;
  transition: all ease-out 0.2s;
  border-radius: 30px;
  padding: 7px 40px;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}


.pricing-box .pricing-box-content-home-packages{
  text-align: left;
  padding: 5px;
  height: 350px;
  /* background-color: #093e64; */
  overflow-y:scroll ;
}
.pricing-box .pricing-box-content-home-packages::-webkit-scrollbar{
width: 0px;
}

.btn-owner-card:hover {
  background: #093e64;
  border: 1px solid #093e64;
  color: #fff !important;
}
.pricing-table-divider {
  display: block;
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  max-width: 90%;
  margin: 20px auto 0;
}
.pricing-table {
  border-radius: 3px;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  text-align: center;
  
}

.pricing-box {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.13);
  border: 2px solid #15124b;
}
.pricing-box:hover {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}
.pricing-table ul li {
  padding: 3px 0;
  font-size: 0.95em;
}
.pricing-table ul {
  color: #000;
  padding: 0px 40px;
  text-align: left;
  list-style: revert!important;
}
.contact-owner-packages{
width: 100%;
min-height: 60px;
display: flex;
 align-items: center;
 justify-content: center;
background-color: #2c354f;
}
.contact-owner-packages-button {
  --green: #1BFD9C;
  font-size: 15px;
  padding: 0.7em 2.7em;
  letter-spacing: 0.06em;
  position: relative;
  font-family: inherit;
  border-radius: 0.6em;
  overflow: hidden;
  transition: all 0.3s;
  color: white;
  line-height: 1.4em;
  border: 2px solid var(--green);
  background: linear-gradient(to right, rgba(27, 253, 156, 0.1) 1%, transparent 40%,transparent 60% , rgba(27, 253, 156, 0.1) 100%);
  color: var(--green);
  box-shadow: inset 0 0 10px rgba(27, 253, 156, 0.4), 0 0 9px 3px rgba(27, 253, 156, 0.1);
  font-weight: 700;
}

.contact-owner-packages-button:hover {
  color: #82ffc9;
  box-shadow: inset 0 0 10px rgba(27, 253, 156, 0.6), 0 0 9px 3px rgba(27, 253, 156, 0.2);
}

.contact-owner-packages-button:before {
  content: "";
  position: absolute;
  left: -4em;
  width: 4em;
  height: 100%;
  top: 0;
  transition: transform .4s ease-in-out;
  background: linear-gradient(to right, transparent 1%, rgba(27, 253, 156, 0.1) 40%,rgba(27, 253, 156, 0.1) 60% , transparent 100%);
}

.contact-owner-packages-button:hover:before {
  transform: translateX(15em);
}
@keyframes rotate {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

.icons-border {
  background-image: linear-gradient(to left, red, blue);
  width: 50px;
  height: 50px;
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.icons-border::before {
  background-image: linear-gradient(to left, red, blue);
  content: "";
  position: absolute;
  z-index: -1;
  width: 50px;
  height: 50px;
  scale: 1.1;
  filter: blur(25px);
  animation: rotate 4s linear infinite;
  border-radius: 100px;
}

.content {
  background-color: #111;
  width: 50px;
  height: 50px;
  border-radius: 19px;
  scale: 0.9;
}
.icons-content a {
  font-size: 30px;
  color: white;
}
@media only screen and (max-width: 750px) {
  .pricing-table h2 {
   
    padding: 12px 10px;
  }
  .price {
    font-size: 20px!important;
  }
  .pricing-table {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .pricing-table > div:nth-child(3) {
    -ms-grid-row: 1;
    grid-row-start: 1;
    grid-row: 1;
  }
  .pricing-table > div:nth-child(2) {
    -ms-grid-row: 2;
    grid-row-start: 2;
    grid-row: 2;
  }
  .pricing-table > div {
    -ms-flex-item-align: top;
    -ms-grid-row-align: top;
    align-self: top;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .pricing-table {
   
    display: grid;
    grid-template-columns: repeat(2, 1fr);
 
}
}