.checkout-sticky-sidebar {
    position: sticky;
    top: 80px;
}
.order-summury-box-text{
    color: #00f;
    line-height: 1.2em;
    font-size: 14px;
    text-align: justify;
    font-weight: 500;
}
.checkout-order-summary-disable {
    cursor: not-allowed;
    color: gray;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
}
.booking-date-time.disable {
    border: 1px solid gray;
    cursor: not-allowed;
}
.booking-date-time {
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #639;
    background: #f8f8f8;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1), inset 0 2px 0 0 hsla(0,0%,100%,.004);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.booking-date-time .info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.booking-date-time.disable .content {
    font-size: 14px;
    color: gray;
    font-weight: 500;
}
.section-box {
    margin: 15px 0;
}
.hostel-orders-details  {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.2em;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
}
.img-cover-no-stretch {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    display: block;
    height: 70px;
    width: 70px;
    margin-right: 5px;
    display: inline;
    border-radius: 5px
}
.hostel-orders-details .orders-cost {
    min-width: 60px;
    text-align: right;
}
.non-important-text {
    color: gray;
    font-size: 14px;
    line-height: 1.2;
    padding: 5px 0;
    display: flex;
    flex-wrap: wrap;
}
.label-left {
    text-align: left;
}
.label-right {
    text-align: right;
}
._hostel-page-final-pricing {
    margin-top: 10px;
    border-top: 1px solid #e7eff9;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}