.modal-content.enq-modal-content {
  width: 30%;
  position:relative;
  top: 20px;
  
}
body.modal-open {
  overflow: hidden;
}

.enq-form-header {
  height: 50px;
  width: 100%;
  background: #15124a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  color: white;
  border-radius: 8px 8px 0px 0px;
}
.enq-form-header h6{
  font-size: 22px;
  font-weight: 600;
  margin-left: 10px;

}

.enq-form 
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  max-width: 480px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.message {
  color: rgba(88, 87, 87, 0.822);
  font-size: 14px;
}

.enq-flex {
  display: flex;
  width: 100%;
  gap: 6px;
}

.enq-form label {
  position: relative;
}

.enq-form label .enq-imput {
  width: 100%;
  padding: 10px 10px 20px 10px;
  outline: 0;
  /* border: 1px solid rgba(105, 105, 105, 0.397); */
  border: 1px solid #15124a;
  border-radius: 5px;
}

.enq-form  label .enq-imput + span {
  position: absolute;
  left: 10px;
  top: 15px;
  color: grey;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

.enq-form  label .enq-imput:placeholder-shown + span {
  top: 15px;
  font-size: 0.9em;
}

.enq-form  label .enq-input:focus + span,.form label .enq-imput:valid + span {
  top: 30px;
  font-size: 0.7em;
  font-weight: 600;
}

.enq-form  label .enq-imput:valid + span {
  color: green;
}

.input01 {
  width: 100%;
  padding: 10px 10px 20px 10px;
  outline: 0;
  /* border: 1px solid rgba(105, 105, 105, 0.397); */
  border: 1px solid #15124a;
  border-radius: 5px;
}

.enq-form  label .input01 + span {
  position: absolute;
  left: 10px;
  top: 50px;
  color: grey;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

.enq-form  label .input01:placeholder-shown + span {
  top: 40px;
  font-size: 0.9em;
}

.enq-form  label .input01:focus + span,.form label .input01:valid + span {
  top: 50px;
  font-size: 0.7em;
  font-weight: 600;
}

.enq-form  label .input01:valid + span {
  color: green;
}

.errors-msgs {
  font-size: 16px;
  text-align: left;
  margin: 5px 0px;
  color: red;
  font-weight: 500;
}

.enq-logo img{
  height: 160px;
}
.rainbow-hover {
  font-size: 16px;
  font-weight: 800;
  color: #ff7576;
  background-color: #2B3044;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px 24px;
  position: relative;
  line-height: 24px;
  border-radius: 9px;
  box-shadow: 0px 1px 2px #2B3044,
    0px 4px 16px #2B3044;
  transform-style: preserve-3d;
  transform: scale(var(--s, 1)) perspective(600px)
    rotateX(var(--rx, 0deg)) rotateY(var(--ry, 0deg));
  perspective: 600px;
  transition: transform 0.1s;
}

.spn{
  background: linear-gradient(
      90deg,
      #866ee7,
      #ea60da,
      #ed8f57,
      #fbd41d,
      #2cca91
    );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  display: block;
}

.rainbow-hover:active {
  transition: 0.3s;
  transform: scale(0.93);
}
@media only screen and (max-width: 600px) {
  .modal-content.enq-modal-content {
    width: 98%;
  
}
}