.riview-section-box-hostel{
    scroll-margin-top: 200px;
    margin-top: 30px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    background-color: #fff;
    padding: 20px;
}
._block-header {
    border-bottom: 1px solid #e7eff9;
    margin-bottom: 20px;
}
._block-header h4{
    font-size: 21px;
    text-align: left;
    color: #15124a;
    font-weight: 700;
}
._single-reviews-list-item {
    border-bottom: 1px solid #e7eff9;
    padding-bottom: 10px!important;
}
._single-reviews-list-item img {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    position: relative;
    padding: 3px;
   
}

.img-cover-no-stretch {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    display: block;
}
.no-gutters>.col, .no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0;
}
.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;

}
._review-customer-name {
    padding-top: 2px;
    font-weight: 600;
    text-overflow: ellipsis;
    max-height: 38px;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 0;
    text-transform: capitalize;
    padding-left: 3px;
    text-align: left;
}
._review-customer-review-date {
    margin: 0;
    color: gray;
    font-weight: 500;
    text-transform: capitalize;
    padding-left: 3px;
    font-size: 16px;
    text-align: left;
}
.hostel-verified-user
{
    color: rgb(255, 105, 97); 
    font-size: 13px;
    text-align: left;
    font-weight: 600;
}
h6.user-riview-star {
    text-align: left;
    font-size: 25px;
}

.user-riview-txt {
    text-align: justify;
    font-size: 14px;
    font-weight: 600;
    padding-right: 10px;
}

.custom-btn {
    width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5),
      7px 7px 20px 0px rgba(0,0,0,.1),
      4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
    display: flex;
   }
   
   .btn-1 {
    background: rgb(96,9,240);
    background: linear-gradient(0deg, rgba(96,9,240,1) 0%, rgba(129,5,240,1) 100%);
    border: none;
   }
   
   .btn-1:before {
    height: 0%;
    width: 2px;
   }
   
   .btn-1:hover {
    box-shadow: 4px 4px 6px 0 rgba(255,255,255,.5),
                 -4px -4px 6px 0 rgba(116, 125, 136, .5), 
       inset -4px -4px 6px 0 rgba(255,255,255,.2),
       inset 4px 4px 6px 0 rgba(0, 0, 0, .4);
   }
   