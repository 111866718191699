.My-booking-screen {
  width: 100%;
  overflow: hidden;
}

.c-pdvaup {
  width: 1180px;
  margin: auto;
  padding: 70px 0px;
  /* background: radial-gradient(black, transparent); */
}
.my-bokking-layout {
  /* width: 100%; */
  height: 100%;
}
.mybooking-heading {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.c-bazc6g {
  padding: 22px 0;
  font-size: 28px;
  font-weight: bolder;
  line-height: 48px;
  color: rgba(51, 51, 51, 1);
}
.u-textCapitalize {
  text-transform: capitalize;
}
.mybooking-hostel-card {
  width: 100%!important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex !important;
  
}
