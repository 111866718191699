.ofers-sliders{
    width: 100%;
  height: 150px;

}
.item.offer-items {
    height: 180px;
    width: 100%;
}
.item.offer-items img {
    height: 100%;
    width: 100%;
}
.ofers-sliders .owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    top: 42px!important;
    height: 30px;
    width: 30px;
    background: rgba(128, 128, 128, 0.5)!important;

}
.ofers-sliders .owl-carousel .owl-nav button.owl-next {
    position: absolute;
    top: 42px!important;
    height: 30px;
    width: 30px;
    background: rgba(139, 137, 137, 0.5)!important;
}
.ofers-sliders .owl-carousel .owl-nav button.owl-prev span {
   font-size: 33px!important;
   position: relative!important;
   top:-10px!important
}
.ofers-sliders .owl-carousel .owl-nav button.owl-next span {
    font-size: 33px!important;
    position: relative !important;
    top: 0px !important;
}
@media only screen and (max-width: 600px) {
    .ofers-sliders .owl-carousel .owl-nav button.owl-prev {
      
        top: 30px!important;
       
    } 
    .ofers-sliders .owl-carousel .owl-nav button.owl-next {
        top: 30px!important;
    }
}