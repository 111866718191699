
.top-fiter-bar-main-box {
    min-height: 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    top: -20px;
    position: relative;
    justify-content: space-between;
  
    width:  100%;
}
.searchCount {
   
    display: flex;
    align-items: center;
    width: 49%;
  
}
.searchCount span{
    font-size: 16px;
    font-weight: 600;
    align-items: center;
   
}
.toolipbar-select-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 49%;
 
}
span.top-fil-bar-srt-txt {
    font-size: 16px;
    font-weight: 500;
}
@media only screen and (max-width: 600px) {
    .searchCount {
        width: 100%;
    }
    .toolipbar-select-box {
     display: none;
        width: 100%;
        /* background-color:rebeccapurple; */
    }
    .top-fiter-bar-main-box {
        top:0px;
    }
    .toolipbar-select-box{
        justify-content: flex-start;
    }
}