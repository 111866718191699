.blog-screens{
width: 100%;
overflow: hidden;
}
.blog-screen-heading {
    height: 150px;
    width: 100%;
    padding: 20px 0px;
    background-color: #15124b;
  
  }
  .blog-screen-heading-text {
    text-align: center;
    color: white;
    font-size: 40px;
    font-weight: 600;
  }
  .blog-screen-sub-text{
    text-align: center;
    color: white;
    font-size: 40px;
    font-weight: 600;
  }
  p.blog-screen-sub-text {
    color: white;
    font-size: 18px;
    text-align: center;
  }
  p.blog-screen-sub-text span a {
    text-decoration: none;
    color: white;
  }