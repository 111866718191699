.ceo-area {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top:20px;
  }
 
  .ceo-image {
    margin: 0px;
    width: 540px;
    height: 540px;
    float:right;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .ceo-image {
      margin-left: 35px;
      width: 345px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .ceo-image {
      margin-bottom: 30px;
      float: none;
    }
  }
  @media only screen and (max-width: 767px) {
    .ceo-image {
      margin-bottom: 30px;
      margin-right: 0;
      float: none;
      width: auto;
    }
    .hidden-sm {
      display: block !important;
      width: 340px;
      height: 85%;
      
    }
    .ceo-img-sm{
      position: relative;
     right: 20px;
    }
  }
  .ceo-image img {
    height: 100%;
    width: 100%;
  }
  
  .ceo-content h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 2px;
  }
  @media only screen and (max-width: 479px) {
    .ceo-content h2 {
      font-size: 30px;
      line-height: 30px;
    }
  }
  .ceo-content h2 span {
    color: #15124b;
  }
  
  .ceo-content h4 {
    font-size: 18px;
    font-weight: 500;
    color: #9b9b9b;
    margin-bottom: 23px;
  }
  
  .ceo-content p {
    font-size: 17px;
    line-height: 27px;
    font-weight: 400;
    margin-top: 10px;
    text-align: justify;
  }
  


 