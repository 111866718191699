.locations {
    display: flex;
    align-items: center;
    background: #f2f2f2;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .locations div:nth-of-type(12) {
    display: flex;
  }
  .locations div:nth-of-type(12) p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.005em;
    color: #ee2e24;
  }
  .locations div:nth-of-type(12) img {
    object-fit: contain;
    margin-left: 5px;
    height: 15px;
    position: relative;
    top: 4px
  }
  .city-name-all {
    position: relative;
    top: 7px;
}
 
@media only screen and (max-width: 600px) {
  .locations{
    display: none;
  }
}
@media only screen and (min-width: 601px) and (max-width: 767px) {
  .locations{
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .locations{
    display: none;
  }

}