.homepage-slide-main-banner{
  min-height: 175px;
    padding: 0 20px;
    width: 100%;
    margin: 5px auto 20px;
}
._taglist {
  background: #fff;
  margin: 0px 20px;
  padding: 10px;
}
.home-page-party-store-banner {
  margin: 5px 10px;
  max-width: 100%;
  cursor: pointer;
  position: relative;
  height: 200px;
  border-radius: 5px;
  background-color: #15124b;
  box-shadow: 0 2px 4px rgba(0,0,0,.3)
}
.homepage-slide-main-banner .slick-next {
  right: 10px!important;
  z-index: 11;
  height: 30px!important;
  width: 30px!important;
  background-color: #e1e1e1!important;
  border-radius: 50%;
}
.homepage-slide-main-banner .slick-prev{
  left: 10px!important;
  z-index: 11!important;
  height: 30px!important;
  width: 30px!important;
  background-color: #e1e1e1!important;
  border-radius: 50%;
}
.homepage-slide-main-banner .slick-prev:before, .slick-next:before {
position: relative;
top:6px;
color: black!important;
}

.image-main-banner {
  width: 100%;
  height: 200px;
  margin: auto;
  display: block;
 
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  position: relative;
  top:2px;
}
.img-cover-no-stretch-slider {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  display: block;
}

.slider-img-blank-div {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  margin: 0;
}
.slider-img-main-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.list-property-banner-text{
  color: white;
  font-size: 26px;
  font-weight: 600;
 padding-top: 30px;
} 
.codepen-button {
  display: block;
  cursor: pointer;
  color: white;
  margin: 0 auto;
  position: relative;
  text-decoration: none;
  font-weight: 600;
  border-radius: 6px;
  overflow: hidden;
  padding: 3px;
  isolation: isolate;
  width: 200px;
  height: 50px;
  top:20px;
}

.codepen-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 400%;
  height: 100%;
  background: linear-gradient(115deg,#4fcf70,#fad648,#a767e5,#12bcfe,#44ce7b);
  background-size: 25% 100%;
  animation: an-at-keyframe-css-at-rule-that-translates-via-the-transform-property-the-background-by-negative-25-percent-of-its-width-so-that-it-gives-a-nice-border-animation_-We-use-the-translate-property-to-have-a-nice-transition-so-it_s-not-a-jerk-of-a-start-or-stop .75s linear infinite;
  animation-play-state: paused;
  translate: -5% 0%;
  transition: translate 0.25s ease-out;
}

.codepen-button:hover::before {
  animation-play-state: running;
  transition-duration: 0.75s;
  translate: 0% 0%;
}

@keyframes an-at-keyframe-css-at-rule-that-translates-via-the-transform-property-the-background-by-negative-25-percent-of-its-width-so-that-it-gives-a-nice-border-animation_-We-use-the-translate-property-to-have-a-nice-transition-so-it_s-not-a-jerk-of-a-start-or-stop {
  to {
    transform: translateX(-25%);
  }
}

.codepen-button span {
  position: relative;
  display: block;
  padding: 8px;
  font-size: 1.1rem;
  background: #000;
  border-radius: 3px;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  ._taglist{
    margin:0px;
    padding: 0px;
  }
  .home-page-party-store-banner {
    margin:5px 8px
  }
  .slick-slide img {
    height: 100%!important;
    width: 100%!important;
    object-fit: unset!important;
  }
}
