.aboutus-area {
  padding-top: 10px;
  padding-bottom: 10px;
}
/*-- aboutus Image --*/
.aboutus-image {
  margin: 0px;
  width: 480px;
  height: 500px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .aboutus-image {
    margin-right: 35px;
    width: 345px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .aboutus-image {
    margin-bottom: 30px;
    float: none;
  }
}
@media only screen and (max-width: 767px) {
  .aboutus-image {
    margin-bottom: 30px;
    margin-right: 0;
    float: none;
    width: auto;
  }
  .hidden-sm {
    display: block !important;
    width: 340px;
    height: 85%;
  }
  .about-right-img{
position: relative;
right: 20px;
  }
}
.aboutus-image img {
  height: 100%;
  width: 100%;
}

/*-- aboutus Content --*/
.aboutus-content h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 2px;
}
@media only screen and (max-width: 479px) {
  .aboutus-content h2 {
    font-size: 30px;
    line-height: 30px;
  }
}
.aboutus-content h2 span {
  color: #15124b;
}

.aboutus-content h4 {
  font-size: 18px;
  font-weight: 500;
  color: #9b9b9b;
  margin-bottom: 23px;
}

.aboutus-content p {
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
  margin-top: 10px;
  font-size: 18px;
}

/*-- counter --*/
.single-counter {
  border: none;
  margin-top: 32px;
  float: left;
  width: 100%;
}
.single-counter {
  float: left;
  width: 25%;
  padding: 28px 15px 28px;
}
@media only screen and (max-width: 767px) {
  .counter .single-counter {
    width: 50%;
  }

  .counter .single-counter + .single-counter {
    border-left: none;
  }
  .counter .single-counter h2 {
    font-size: 30px;
    color: #15124b;
    line-height: 24px;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 8px;
  }
  .counter .single-counter p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    color: #8f8f8f;
  }
}
