@media only screen and (max-width: 767px) {
  .hostelinfo-section {
    flex-wrap: wrap;
  }
  .left-carousel {
    width: 100%;
  }
  .c-1m75bgc {
    display: none;
  }
  .image-caraousel {
    height: 230px!important;
  }
  .right-careousel-info {
    width: 100%;
  }
  .hostel-info-items-head {
    padding: 0px;
  }
  .listingHotelDescription__contentWrapper--left {
    width: 100%;
  }
  .listingHotelDescription__contentWrapper--right {
    width: initial;
    min-width: initial;
  }
  .amenityWrapper {
    flex-wrap: wrap;
  }
  .amenityWrapper__amenity {
    padding-right: 0px;
    width: 33.33% !important;
    justify-content: flex-start;
  }
  .hostel-info-items-head{
    padding: 0px!important;
  }
  .hostelinfo-section{
    padding: 0px!important;
  }
  span.hostel-info-seat-price-text {
    font-weight: 500;
    padding: 0px 5px;
    font-size: 14px;
}
.hostel-info-seat-price ul li img {
  width: 20px!important;
}
.listingHotelDescription__hotelAddress {
  padding-top: 0px;
  position: relative;
  bottom: 8px;
}
.hostelRating {
  margin-top:0px;
}
.listingHostelDescription__labels {
  display: none;
 
}
h3.listingHotelDescription__hotelName.d-textEllipsis{
display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
}
.u-line--clamp-2{
  font-size: 16px;
}
.listingHostelDescription__btnWrapper {
  display: flex;
justify-content: space-between!important;
width: 90%;
margin:auto;
}
.d-greenButton, .d-whiteButton {
  padding: 6px 10px !important;
  min-width: 100px;
  min-height: 32px;
}

}
@media only screen and (min-width: 768px) and (max-width: 1180px) {
.right-careousel-info{
width:60%;
}
.amenityWrapper__amenity{
  width: 30%;
}
}