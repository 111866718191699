.red-rectangle {
  position: relative;
  background-image: url(./../../assets/images/hostelbanner.png);
  height: 250px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 99;
}
.heading-red-rectangle {
  font-size: 28px;
  color: white;
  font-weight: 700;
  padding-top: 10px;
}

.search-bar-home-page {
  height: 24%;
  width: 90%;

  margin: auto;
  /* background: red; */
  margin-top: 3%;
}
.serch-cont-fluid {
  padding: 0px;
}
.serch-bar-cont {
  padding: 0px;
  /* background-color: blueviolet; */
}
.serch-col {
  padding: 0px;
}
.search-field {
  height: 60px;
  width: 100%;
  /* background-color: aqua; */
}
.search-field input {
  height: 100%;
  width: 100%;
  border-radius: 5px 0px 0px 5px;
  padding-left: 15px;
}
.react-datepicker__input-container {
  height: 100%;
}
.react-datepicker-wrapper {
  height: 100%;
}

.react-datepicker__input-container .search-bar-date-input {
  height: 100%;
  width: 100% !important;
  min-width: 338px;
  padding-left: 10px;
}
.serch-col input:focus-visible {
  outline: none;
}
select.search-bar-select.form-select {
  width: 100%;
  height: 100%;
  /* height: 59px; */
  border: 1px solid black;
}
select.search-bar-select.form-select:focus {
  outline: none;
  box-shadow: none;
}

.search-btn-home-page {
  background-color: initial;
  background-image: linear-gradient(-180deg, #00d775, #00bd68);
  border-radius: 0px 0px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial,
    sans-serif;
  height: 59px;
  line-height: 59px;
  outline: 0;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  right: 6px;
  /* top: 1px; */
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 90%;
  z-index: 9;
  border: 0;
  border-radius: 0px 5px 5px 0px;
}

.search-btn-home-page:hover {
  background: #00bd68;
}

@media only screen and (max-width: 767px) {
  .serch-bar-cont {
    overflow: hidden;
  }
  .red-rectangle {
    height: 350px;
  }

  .heading-red-rectangle {
    font-size: 24px;
  }

  .search-bar-home-page {
    height: 70%;
    width: 100%;
    padding: 5px 20px;
  }
  ul.suggestions-list {
    position: relative;
    z-index: 9999;
  }
  li.suggestion-list-text-box {
    padding: 8px 20px !important;
  }
  .search-field input {
    height: 45px;
    width: calc(100% - 20px);
    border-radius: 5px;
  }
  .react-datepicker-wrapper {
    height: 100%;
    min-height: 40px;
    width: calc(100% - 20px);
    position: relative;
    bottom: 10px;
  }

  .react-datepicker__input-container {
    height: 100%;
    min-height: 40px;
    /* z-index: -9; */
  }
  .react-datepicker__input-container input {
    height: 100%;
    min-height: 40px;
    width: calc(100% - 20px);
    border-radius: 5px;
    background: white;
  }
  select.search-bar-select.form-select {
    min-height: 40px;
    width: calc(100% - 20px);
    margin-left: 10px;
    border-radius: 5px !important;
  }

  .search-btn-home-page {
    height: 46px;
    /* min-height: 40px; */
    width: calc(100% - 20px);
    line-height: 40px;
    margin-left: 12px;
    border: 1px solid black;
    margin-top: 10px;
    border-radius: 5px;
    z-index: 0;
  }
}
ul.suggestions-list {
  background-color: gainsboro;
  list-style: none;
  text-align: left;
  padding: 0px;
}
li.suggestion-list-text-box {
  background: white;
  border-bottom: 1px solid green;
  padding: 8px 10px;
  font-size: 16px;
  font-weight: 600;
}
.no-data-found {
  background: white;
  text-align: left;
  padding: 8px;
  font-weight: 600;
  color: red;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* Add styles for tablet view here */
  .red-rectangle {
    min-height: 320px;

  }
  .search-field {
    height: 50px;
    margin: auto !important;
    width: 80%;
  }
  .search-field input {
    border-radius: 5px;
  }
  .react-datepicker-wrapper {
    width: 80%;
    height: 50px;
    margin-top: 10px;
    border-radius: 5px !important;
  }
  .react-datepicker__input-container .search-bar-date-input {
    border-radius: 5px;
  }
  select.search-bar-select.form-select {
    width: 80%;
    margin: auto;
    height: 50px;
    border-radius: 5px !important;
    margin-top: 5px;
  }
  .search-btn-home-page {
    height: 50px;
    width: 80%;
    border-radius: 5px;
    margin-left: 10px;
    margin-top:5px;
    z-index: 0;
    line-height: 50px;
    font-size: 20px;
    font-weight: 600;
  }
  ul.suggestions-list {
    position: relative;
    z-index: 9999;
  }
  .error-msg{
    display: none;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .search-field input {
    width: 90%;
  }
  .react-datepicker__input-container {
    width: 50%;
    right: 110px;

    top: 1px;
  }
  .react-datepicker__input-container {
    height: 98%;
  }
  .react-datepicker__input-container .search-bar-date-input {
    min-width: 166px;
    padding-left: 10px;
    margin-left: 90px;
    height: 58px;
  }
  select.search-bar-select.form-select {
    height: 59px;
  }
  .search-btn-home-page {
    width: auto;
    left: 0.5px;
  }
  
}

/* 
@media only screen and (max-width: 600px) {
  
 
}
@media only screen and (min-width: 601px) and (max-width: 767px) {
 
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
 
}
@media only screen and (min-width: 1000px) and (max-width: 1280px) {

}  */
