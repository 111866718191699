/* Hide collapsed navbar toggler button */
button.home-header-nave-sticky.navbar-toggler.collapsed {
  display: none;
}

/* Styling navbar */
nav.bg-body-tertiary.mb-0.sticky-top.navbar.navbar-light {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: white !important;
}

/* Adjust logo size */
img.home-header-nave-sticy-logo {
  margin-top: 10px;
  height: 70px;
}

/* Styling login header */
img.img-login-header {
  height: 30px;
  cursor: pointer;
}

span.login-btn-home {
  margin-left: 10px;
  font-size: 21px;
  font-weight: 600;
  cursor: pointer;
}

/* Styling user login header */
img.img-user-login-header {
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
}

span.user-login-btn-name {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

/* Styling promo card icon wrapper */
.promoCard__iconWrapper-login {
  width: 220px;
  display: flex;
  justify-content: left;
  align-items: center;
}

/* Styling navigation bar */
.nave-bar-home.container-fluid {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* Adjust width of navigation bar header */
.nave-bar-home-header {
  width: 78%;
}

span.navbar-brand {
  width: 20%;
}

/* Styling various headers */
.login-home-heads,
.calls-home-heads,
.property-home-heads {
  height: 80px;
  float: right;
  display: flex;
  align-items: center;
  width: 250px;
  justify-content: center;
}

.calls-home-heads,
.property-home-heads {
  border-radius: 0px 5px 5px 0px;
  border-right: 1px solid black;
}

/* Styling promo card icon wrapper */
.promoCard__iconWrapper {
  display: inline-block;
  width: 40px;
  height: 35px;
  border-radius: 2px;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  box-sizing: border-box;
}

.promoCard__iconWrapper span img {
  height: 100%;
  width: 100%;
}

/* Styling promo card content wrapper */
.promoCard__contentWrapper {
  display: inline-block;
  width: calc(100% - 50px);
  margin-left: 7px;
  white-space: nowrap;
}

/* Styling promo card text */
.promoCard__text {
  color: #000;
  margin-bottom: 2px;
}

.promoCard__text span a {
  color: black;
  font-weight: 700;
  cursor: pointer;
}

/* Styling promo card text and subtext */
.d-text16 {
  font-size: 16px;
  text-align: left;
}

.promoCard__text.d-text16.is-fontBold {
  margin-left: 25px;
  text-align: left;
}

.promoCard__subtext.d-text14 {
  margin-left: 25px;
  text-align: left;
}

.promoCard__subtext.d-text14 a {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
}

/* Styling dropdown menu */
.dropdown-menu.show {
  display: block!important;
  width: 230px!important;
  margin: 0 !important;
  padding: 0!important;
  margin-top: 1px !important;
  border-radius: 0px !important;
  border: 1px solid white  !important;
}

button#dropdown-basic\ {
  background: white;
  color: black;
  border: none;
}

a.dropdown-item {
  border-bottom: 1px solid black!important;
  padding: 8px 8px !important;
  font-size: 16px!important;
  font-weight: 500!important;
}

.dropdown-toggle::after {
  display: none!important;
}
button#dropdown-basic {
  background: white!important;
  color: black!important;
  border: none!important;
}

span.profile-icons svg {
  font-size: 25px;
  color: #15124b;
}
span.profile-nave-item {
  position: relative;
  top: 2px;
  font-weight: 600;
  left: 5px;
}

/* Media queries for responsive design */
@media only screen and (max-width: 600px) {
  .calls-home-heads,
  .property-home-heads {
    display: none;
  }

  .nave-bar-home-header {
    width: 50%;
    display: none;
  }

  span.navbar-brand {
    width: 100%;
  }

  img.img-user-login-header {
    height: 30px;
    cursor: pointer;
  }

  span.user-login-btn-name {
    font-size: 14px;
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .promoCard__iconWrapper-login {
    margin-left: 25px;
    justify-content: flex-end;
  }

  img.home-header-nave-sticy-logo {
    height: 70px;
  }

  .login-home-heads {
    height: 50px;
    width: 180px;
  }

  .dropdown-menu.show {
    width: 155px!important;
    display: none!important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
  .calls-home-heads,
  .property-home-heads {
    display: none;
  }

  .nave-bar-home-header {
    width: 50%;
  }

  span.navbar-brand {
    width: 45%;
  }

  img.img-user-login-header {
    height: 35px;
    cursor: pointer;
  }

  span.user-login-btn-name {
    font-size: 16px;
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .promoCard__iconWrapper-login {
    margin-left: 25px;
    justify-content: flex-end;
  }

  img.home-header-nave-sticy-logo {
    height: 45px;
    object-fit: cover;
  }

  .login-home-heads {
    height: 60px;
    width: 185px;
  }

  .dropdown-menu.show {
    width: 155px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .calls-home-heads,
  .property-home-heads {
    display: none;
  }

  .nave-bar-home-header {
    width: 50%;
  }

  span.navbar-brand {
    width: 45%;
  }

  img.img-user-login-header {
    height: 42px;
    cursor: pointer;
  }

  span.user-login-btn-name {
    font-size: 18px;
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .promoCard__iconWrapper-login {
    justify-content: flex-end;
  }

  img.home-header-nave-sticy-logo {
    height: 70px;
    object-fit: cover;
  }

  .login-home-heads {
    height: 60px;
  }

  .dropdown-menu.show {
    width: 200px;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1270px) {
  img.home-header-nave-sticy-logo {
    height: 52px;
    object-fit: cover;
  }
  span.navbar-brand {
    width: 20%;
  }
  .nave-bar-home-header {
    width: 78%;
  }
  .dropdown-toggle::after {
    display: none;
  }
}
