.footer {
  /* background: #6d787d; */
  padding: 50px 80px;
  color: #fff;
  background: url("./../../assets/images/hostelbanner.png");
  background-repeat: no-repeat, repeat;
  background-size: cover;
}
.footer-sec1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
}
.footer-sec1 div:nth-of-type(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}
.footer-sec1 div:nth-of-type(1) {
  border: none;
  padding-bottom: 30px;
  display: flex;
}
.footer-sec1 div:nth-of-type(2) p,
.footer-sec1 div:nth-of-type(1) p {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;

  color: #f4fcfe;
}
.footer-sec1 div:nth-of-type(2) button {
  width: 173px;
  height: 48px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}
.footer-sec1 div:nth-of-type(2) button img {
  margin-right: 5px;
}
.footer-sec1 div:nth-of-type(1) > *,
.footer-sec1 div:nth-of-type(2) > * {
  margin-right: 15px;
  object-fit: contain;
}
.footer-sec2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
}
.footer-sec2 div:nth-of-type(1) {
  border: none;
  /* display: grid; */
  grid-template-columns: 40% 60%;
  width: 40%;
}
.footer-sec1 div:nth-of-type(1) img {
  height: 60%;
  width: 23%;
  border-radius: 5px;
  object-fit: cover;
}
p.footer-logo-text {
  position: relative;
  top: 10px;
}
p.footer-logo-text2 {
  position: relative;
  top: 10px;
}
/* .footer-sec2 div:nth-of-type(1) p {
    width: 180%;
    margin-bottom: 10px;
  } */

.footer-sec2 div:nth-of-type(2) {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 40%;
  border-right: 1px solid hsla(0, 0%, 100%, 0.5);
  padding-left: 45px;
  border-left: 1px solid hsla(0, 0%, 100%, 0.5);
}
.footer-sec2 div:nth-of-type(2) p {
  margin: 10px;
  text-align: left;
}
.footer-sec2 div:nth-of-type(2) p:hover {
  text-decoration: underline;
}
.footer-sec2 div:nth-of-type(3) {
  width: 20%;
}
.footer-sec2 div:nth-of-type(3) p {
  margin: 20px;
  text-align: left;
}
.footer-sec2 div:nth-of-type(3) p:hover {
  text-decoration: underline;
}
h6.about-footer-heading-txt {
  font-size: 20px;
  text-align: left;
  padding: 0px 10px;
  font-weight: 700;
}
.about-footer p {
  text-align: left;
  padding: 10px;
}
.footer-sec2 p a {
  text-decoration: none;
  color: white;
}
.footer-sec1 div:nth-of-type(2) button a {
  color: black;
}

.fotter-property-button {
  display: block;
  cursor: pointer;
  color: white;
  margin: 0 auto;
  position: relative;
  text-decoration: none;
  font-weight: 600;
  border-radius: 6px;
  overflow: hidden;
  padding: 3px;
  isolation: isolate;
  height: 50px;
  line-height: 10px;
  width: 300px;
}

.fotter-property-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 400%;
  height: 100%;
  background: linear-gradient(
    115deg,
    #4fcf70,
    #fad648,
    #a767e5,
    #12bcfe,
    #44ce7b
  );
  background-size: 25% 100%;
  animation: an-at-keyframe-css-at-rule-that-translates-via-the-transform-property-the-background-by-negative-25-percent-of-its-width-so-that-it-gives-a-nice-border-animation_-We-use-the-translate-property-to-have-a-nice-transition-so-it_s-not-a-jerk-of-a-start-or-stop
    0.75s linear infinite;
  animation-play-state: paused;
  translate: -5% 0%;
  transition: translate 0.25s ease-out;
}

.fotter-property-button:hover::before {
  animation-play-state: running;
  transition-duration: 0.75s;
  translate: 0% 0%;
}
i.property-icon svg {
  font-size: 30px;
  position: relative;
  bottom: 8px;
}
i.property-icon-text {
  position: relative;
  /* top: 0px; */
  bottom: 8px;
  font-style: normal;
  left: 5px;
}
@keyframes an-at-keyframe-css-at-rule-that-translates-via-the-transform-property-the-background-by-negative-25-percent-of-its-width-so-that-it-gives-a-nice-border-animation_-We-use-the-translate-property-to-have-a-nice-transition-so-it_s-not-a-jerk-of-a-start-or-stop {
  to {
    transform: translateX(-25%);
  }
}

.fotter-property-button span {
  position: relative;
  display: block;
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
  background: #000;
  border-radius: 3px;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .footer-sec1 {
    flex-wrap: wrap;
  }
  .fotter-property-button {
    width: revert!important;
  }
  .footer-sec1 div:nth-of-type(1) {
    width: 100%;
    flex-wrap: wrap;
  }
  .footer-sec1 div:nth-of-type(1) > *,
  .footer-sec1 div:nth-of-type(2) > * {
    margin-right: 0px;
  }
  .footer-sec1 div:nth-of-type(2) p,
  .footer-sec1 div:nth-of-type(1) p {
    font-size: 16px;
  }
  p.footer-logo-text {
    left: 15px;
  }
  .footer-sec1 div:nth-of-type(1) img {
    width: 100%;
    height: 90px;
  }
  .footer {
    padding: 10px 10px;
    overflow: hidden;
  }
  .footer-sec1 div:nth-of-type(2) {
    flex-wrap: wrap;
  }
  .footer-sec1 div:nth-of-type(2) button {
    width: 100%;
    justify-content: flex-start;
    font-size: 20px;
    font-weight: 600;
  }
  .footer-sec1 div:nth-of-type(2) button img {
    margin-right: 20px;
    width: 45px;
  }
  .footer-sec2 {
    flex-wrap: wrap;
    width: 100%;
    padding: 15px 0px;
  }
  .footer-sec2 div:nth-of-type(1) {
    width: 100%;
    border-bottom: 2px solid hsla(0, 0%, 100%, 0.5);
  }
  .about-footer p {
    text-align: justify;
  }
  .footer-sec2 div:nth-of-type(2) {
    width: 100%;
    border: none;
    padding-left: 0px;
    border-bottom: 2px solid hsla(0, 0%, 100%, 0.5);
    padding: 20px 0px;
  }
  .footer-sec2 div:nth-of-type(2) p {
    margin: 0px;
    margin-top: 10px;
  }
  .footer-sec2 div:nth-of-type(3) {
    width: 100%;
  }
  .fotter-property-button {
    width: 90%;
    right: 20px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 767px) {
  .footer-sec1 {
    flex-wrap: wrap;
  }

  .footer-sec1 div:nth-of-type(1) {
    width: 100%;
    flex-wrap: wrap;
  }
  .footer-sec1 div:nth-of-type(1) > *,
  .footer-sec1 div:nth-of-type(2) > * {
    margin-right: 0px;
  }
  .footer-sec1 div:nth-of-type(2) p,
  .footer-sec1 div:nth-of-type(1) p {
    font-size: 16px;
  }
  p.footer-logo-text {
    left: 15px;
  }
  .footer-sec1 div:nth-of-type(1) img {
    width: 100%;
    height: 90px;
  }
  .footer {
    padding: 10px 10px;
    overflow: hidden;
  }
  .footer-sec1 div:nth-of-type(2) {
    flex-wrap: wrap;
  }
  .footer-sec1 div:nth-of-type(2) button {
    width: 100%;
    justify-content: flex-start;
    font-size: 20px;
    font-weight: 600;
  }
  .footer-sec1 div:nth-of-type(2) button img {
    margin-right: 20px;
    width: 45px;
  }
  .footer-sec2 {
    flex-wrap: wrap;
    width: 100%;
    padding: 15px 0px;
  }
  .footer-sec2 div:nth-of-type(1) {
    width: 100%;
    border-bottom: 2px solid hsla(0, 0%, 100%, 0.5);
  }
  .about-footer p {
    text-align: justify;
  }
  .footer-sec2 div:nth-of-type(2) {
    width: 100%;
    border: none;
    padding-left: 0px;
    border-bottom: 2px solid hsla(0, 0%, 100%, 0.5);
    padding: 20px 0px;
  }
  .footer-sec2 div:nth-of-type(2) p {
    margin: 0px;
    margin-top: 10px;
  }
  .footer-sec2 div:nth-of-type(3) {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .footer {
    padding: 20px 15px;
  }
  .footer-sec1 {
    flex-wrap: wrap;
    width: 100%;
  }
  .footer-sec1 div:nth-of-type(1) {
    width: 100%;
    align-items: center;
  }
  .footer-sec1 div:nth-of-type(1) img {
    width: 35%;
  }
  .footer-sec1 div:nth-of-type(2) {
    justify-content: flex-start;
    width: 100%;
  }
  .footer-sec1 div:nth-of-type(2) button {
    width: 240px;
    height: 50px;
    margin-left: 20px;
    justify-content: flex-start;
    font-size: 18px;
    font-weight: 600;
  }
  .footer-sec1 div:nth-of-type(2) button img {
    margin-right: 20px;
    height: 40px;
    margin-left: 5px;
  }
  .footer-sec2 {
    flex-wrap: wrap;
    width: 100%;
  }
  .footer-sec2 div:nth-of-type(1) {
    width: 100%;
    border-bottom: 2px solid hsla(0, 0%, 100%, 0.5);
  }
  .about-footer p {
    text-align: justify;
  }
  .footer-sec2 div:nth-of-type(2) {
    width: 50%;
    border-left: none;
    padding-left: 0px;
  }
  .footer-sec2 div:nth-of-type(3) {
    width: 50%;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .footer {
    padding: 30px 15px;
  }
  .footer-sec1 div:nth-of-type(1) {
    display: flex;
    align-items: center;
    width: 50%;
  }
  p.footer-logo-text {
    font-size: 15px !important;
  }
  .footer-sec1 div:nth-of-type(2) {
    width: 50%;
  }
  p.footer-logo-text2 {
    font-size: 15px !important;
  }
  .footer-sec1 div:nth-of-type(2) button {
    width: 165px;
    font-weight: 500;
  }
}
