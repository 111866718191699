.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  /* width: 100%; */
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: 2% auto;
  /* padding: 20px; */
  border: 1px solid #888;
  width: 50%;
}

.close {
  color: white;
  float: right;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  text-align: right;
  margin-right: 5px;
  position: relative;
  bottom: 8px;
}
/* new css adding here */

/* new css end here */

body.modal-open {
  overflow: hidden;
}
.modal-seatVailibility-header {
  height: 50px;
  width: 100%;
  background: #15124b;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
h6.heding-text-seat-availble {
  color: white;
  font-size: 18px !important;
  margin-left: 10px !important;
  font-weight: 600 !important;
}
.first-header-seat-avilability-code1 {
  background: #2db34a;
  border-radius: 6px;
  color: #fff;
  display: block;
  font: 20px/24px "Source Code Pro", Inconsolata, "Lucida Console", Terminal,
    "Courier New", Courier;
  padding: 5px 15px;
  text-align: center;
  min-height: 35px;
}
.first-header-seat-avilability-code {
  /* background: #15124b; */
  background: #fff;
  border-radius: 6px;
  /* color: #fff; */
  color: black;
  display: block;
  font: 14px/24px "Source Code Pro", Inconsolata, "Lucida Console", Terminal,
    "Courier New", Courier;
  padding: 24px 15px;
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.first-header-seat-avilability,
.first-header-seat-avilability-section {
  margin: 1.5% 24px 1.5%;
}
.first-header-seat-avilability-section {
  float: left;
  width: 30%;
}
.first-floor-all-section-box {
  display: flex;
  width: 100%;
}
.first-header-seat-avilability-code img {
  width: 50px;
}
.first-header-seat-avilability-code h6 {
  font-size: 16px;
  text-align: center !important;
  margin-top: 10px;
}
.select-bed {
  --color: #fff;
  font-family: inherit;
  display: inline-block;
  line-height: 6px;
  height: 1.6em;
  background-color: #15124b;
  padding: 12px 5px;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--color);
  transition: color 0.5s;
  z-index: 1;
  font-size: 14px;
  border-radius: 6px;
  font-weight: 500;
  color: var(--color);
}

.select-bed:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--color);
  height: 150px;
  width: 200px;
  border-radius: 50%;
}

.select-bed:hover {
  color: #fff;
  background-color: #15124b;
}

.select-bed:before {
  top: 100%;
  left: 100%;
  background-color: #15124b;
  transition: all 0.7s;
}

.select-bed:hover:before {
  top: -30px;
  background-color: #15124b;
  left: -30px;
}

.select-bed:active:before {
  background: #15124b;
  transition: background 0s;
}

@media only screen and (max-width: 600px) {
  .modal-content.seat-avilablity-modal-content {
    width: 98%;
  }
  .first-floor-all-section-box {
    flex-wrap: wrap;
  }
  .first-header-seat-avilability-section {
    width: initial!important;
  }
  .section-box-3 {
    width: 100%;
  }
  .first-header-seat-avilability, .first-header-seat-avilability-section {
    margin: 1.5% 17px 1.5%;
}
}
