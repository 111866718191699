.c-1hmw6f1 {
  opacity: 0.9;
  border-radius: 4px;
  background: linear-gradient(265deg, #ef4123, #ee3f25 48%, #d11450);
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 0px;
  cursor: pointer;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.c-1b11azu {
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 24px;
}
.c-1b11azu svg {
  height: 16px;
  width: 16px;
  color: red;
  background-color: white;
  border-radius: 50%;
}
.c-1s9cs89 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  margin-left: 8px;
  max-width: 230px;
  text-align: left;
}
.c-1w5jjrs {
  color: #fff;
  padding: 2px 10px;
  margin-right: 24px;
  position: relative;
  cursor: pointer;
  text-align: center;
}
.c-22w5bk {
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  -webkit-letter-spacing: 0.43px;
  -moz-letter-spacing: 0.43px;
  -ms-letter-spacing: 0.43px;
  letter-spacing: 0.43px;
  font-weight: bold;
}
.c-1w5jjrs::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
}
.c-2xmewo {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 16px 24px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  /* box-shadow: 0 2px 6px 0 rgba(125, 125, 125, 0.1); */
  /* border: solid 1px #f0f0f0; */
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  box-sizing: border-box;
  margin-bottom: 30px !important;
}
.c-1w4qezm {
  margin: 0px 24px;
  width: 100%;
}



.d-body-lg {
  font-size: 16px;
  line-height: 25px;
}


.listingPrice__perRoomNight,
.listingPrice__text {
  font-size: 12px;
  color: #6d787d;
}
.c-1thcb3a {
  display: flex;
  margin-top: 16px;
  width: 100%;
  box-shadow: 0 2px 6px 0 rgba(125, 125, 125, 0.1);
  cursor: pointer;
  border: solid 1px #f0f0f0;
}
.c-1pzykbe {
  display: flex;
  border-radius: 4px;
  padding: 0px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.datePickerDesktop--hostel {
  border: 0;
  width: 100%;
  margin: 0 12px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 14px 0 !important;
}
.datePickerDesktop {
  position: relative;
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.07);
  transition: 0.2s;
  box-sizing: border-box;
}
.hostel-row {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
}
.hostel-row.hostel-row--no-spacing > .hostel-cell {
  margin: 0;
}
.datePickerDesktop--hostel .datePickerDesktop__checkInOut {
  font-size: 14px;
  color: #0c0a15;
  line-height: 1.71;
  font-weight: 600;
  background: #fff;
  padding: 0px 14px;
  display: block;
}
.datePickerDesktop .datePickerDesktop__checkInOutText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.datePickerDesktop__arrowWrapper--hsotel {
  margin: 0 4px;
}
.datePickerDesktop__arrowWrapper {
  display: inline-block;
  margin: 0 10px;
}
.datePickerDesktop .datePickerDesktop__checkInOutText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.c-14mt3w6 {
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: relative;
}
.guestRoomPicker--hostel {
  border: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 14px 2 !important;
}
.guestRoomPicker {
  position: relative;
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.07);
  transition: 0.2s;
  box-sizing: border-box;
}
.guestRoomPicker--hostel .guestRoomPicker__guestRoomCount {
  font-size: 14px;
  color: #0c0a15;
  line-height: 1.71;
  font-weight: 600;
  background: #fff;
  padding: 0;
  margin-left: 22px !important;
  margin-right: 12px !important;
}
.c-aw4f1d {
  display: flex;
  padding: 12px 0px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(125, 125, 125, 0.1);
  border: solid 1px #f0f0f0;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-top: 16px;
}
.c-1pr5noq {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px 16px;
}
.c-1o86iao {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 20px;
  width: 20px;
  font-size: 20px;
  position: relative;
  top: 2px;
}
.c-1w0k317 {
  margin-left: 10px;
}
.c-pbyyuk {
  display: -ms-flexbox;
  display: flex;
  margin-right: 12px;
}
.c-pbyyuk a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}
.c-1qrh5ub {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  outline: 0;
  pointer-events: auto;
  height: 13px;
  width: 13px;
}
.c-1qrh5ub svg {
  color: #ee3f25;
  font-size: 15px;
}
.c-1kd3zqj {
  margin: 32px 0 0;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}
.c-1kd3zqj svg {
  height: 18px;
  width: 18px;
  color: white;
  background: #f5a623;
  border-radius: 50%;
}

.c-k008qs {
  display: -ms-flexbox;
  display: flex;
}
.c-z7mtfw {
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}
.c-kawmsn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  font-size: 14px;
  line-height: 1.14;
  color: #222;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 12px;
}
.c-1ixbp0l {
  pointer-events: none;
}
.c-1gxq3sh {
  padding: 4px 8px;
  border-radius: 4px;
  border: solid 1px #2ec478;
  background-color: #effcf5;
  color: #698e7f;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 8px;
  cursor: pointer;
}
.c-siw9gi {
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  position: relative;
}
.checkbox:last-of-type {
  margin-bottom: 0;
}
.c-1wjqvmt {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.checkbox {
  display: flex;
  position: relative;
  cursor: pointer;
  margin-bottom: 18px;
  padding-left: 28px;
  user-select: none;
  vertical-align: middle;
}
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkbox input:checked ~ span {
  background-color: #1ab64f;
  border: none;
}

.c-1ltqz98 {
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  top: -4px !important;
}
.checkbox__checkmark--detail {
  top: 0px !important;
  left: 10px !important;
}
.checkbox__checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  transform: translateY(20%);
  height: 16px;
  width: 16px;
  /* background-color: #f1f2f3; */
  border-radius: 2px;
  padding: 4px 2px;
  box-sizing: border-box;
  border: 1px solid #a6a6a6;
  overflow: hidden;
  background-color: #1ab64f !important;
}
.checkbox input:checked ~ span {
  background-color: #1ab64f !important;
  border: none;
}
.rgt-tick svg {
  background-color: #1ab64f !important;
  font-size: 14px;
  color: white;
  position: absolute;
}
.c-8atqhb {
  width: 100%;
}
.c-1w7vvml {
  margin-top: 24px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 14px;
}
.c-1trau93 {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: normal;
  -ms-flex-align: normal;
  align-items: normal;
}
.c-scnr7d {
  height: 16px;
  width: 16px;
  /* background: #222; */
  border-radius: 100%;
}
.c-scnr7d img {
  border-radius: 50%;
  position: relative;
  bottom: 4px;
  right: 2px;
}
.c-1c0296d {
  font-size: 14px;
  line-height: 1.14;
  color: #000000;
  margin-left: 12px;
}
.c-70qvj9 {
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.c-17mtkgf {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  color: #000000;
  margin-right: -20px;
}

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* margin-top: 10px; */
}

.checkbox input {
  position: absolute;
  width: 0;
  left: 50px;
  height: 0;
  opacity: 0;
  cursor: pointer;
}

.checkbox .checkmark {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: #acacac;
  border-radius: 3px;
  outline: 1px solid #acacac;
  transition: all 0.2s ease;
}

.checkbox:hover .checkmark {
  background: #f4f4f5;
  transition: all 0.2s ease;
}

.checkbox input:checked ~ .checkmark {
  background: #3f7fed;
  outline: 1px solid rgb(95, 126, 240);
}

.checkbox input[type="radio"] ~ .checkmark {
  border-radius: 50%;
}

.checkbox .checkmark::after {
  position: absolute;
  display: block;
  content: "";
  left: 50%;
  top: 40%;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -moz-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  opacity: 0;
  transition: all 0.2s ease;
}

.checkbox input:checked ~ .checkmark::after {
  opacity: 1;
  transition: all 0.2s ease;
}
.c-1fnh7lz {
  display: flex;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  width: 100%;
  margin-top: 16px;
}
.c-1reom5a {
  width: 100%;
  background: #212121;
  border-radius: 6px;
  padding: 12px 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.c-1rr4qq7 {
  flex: 1;
}
.c-1xoeeuh {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #ffffff;
}
.c-10l0xlf {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  -webkit-letter-spacing: 0.2px;
  -moz-letter-spacing: 0.2px;
  -ms-letter-spacing: 0.2px;
  letter-spacing: 0.2px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}
.c-16jy9gd {
  margin-left: 8px;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.c-vqe3zw {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: end;
  -ms-flex-align: end;
  align-items: end;
}
.c-1jn1mjj {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}
.c-c9jprc {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  -webkit-letter-spacing: 0.2px;
  -moz-letter-spacing: 0.2px;
  -ms-letter-spacing: 0.2px;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.5);
}
.c-t958pf {
  width: 100%;
  margin-top: 24px;
  border-top: dashed 1px rgba(151, 151, 151, 0.4);
}
.c-1w7vvml {
  margin-top: 24px;
  width: 100%;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 14px;
}
.c-1trau93 {
  display: flex;
  -webkit-align-items: normal;
  -webkit-box-align: normal;
  -ms-flex-align: normal;
  align-items: normal;
}
.c-70qvj9 {
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.c-2jict3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  color: #000000;
  margin-right: 30px;
}
.c-1f2lvne {
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 14px;
  line-height: 1.14;
  color: #000000;
  position: relative;
}
.c-gcuuxt {
  display: flex;
  -webkit-align-items: normal;
  -webkit-box-align: normal;
  -ms-flex-align: normal;
  align-items: normal;
  flex-direction: column;
  position: relative;
  width: 100%;
  text-align: left;
}
.c-1l4repk {
  font-size: 12px;
  color: #6d787d;
  margin-top: 4px;
  max-width: 240px;
}
.c-nx2p0o {
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.c-2jict3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  color: #000000;
  margin-right: 30px;
}
.c-1wso87h {
  display: flex;
  width: 100%;
  margin-top: 22px;
}
.c-u65gu2 {
  border-radius: 4px;
  background-color: #1ab64f;
  border: 0px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
  padding: 14px;
  width: 100%;
  outline: 0;
}
.c-1glae7g {
  width: 100%;
  padding-top: 12px !important;
}
.c-1y9junu {
  padding-bottom: 12px;
  display: flex;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  position: relative;
  outline: none;
}
.c-13zw0qm {
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 16px;
  width: 14px;
  margin-left: 8px;
  outline: 0px;
  pointer-events: auto;
  color: #ee2e24;
}
.c-1lxcwxd:hover {
  cursor: pointer;
}
.c-1lxcwxd {
  font-size: 16px;
  font-weight: 600 !important;
  color: #ee2e24;
  outline: 0;
  pointer-events: auto;
  font-size: 14px;
}
span.c-13zw0qm svg {
  font-size: 24px !important;
  position: relative;
  top: 5px;
}
.c-1ccy8kh {
  padding: 0px 60px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.38);
  background: #fff;
  padding-left: 0px;
}
.c-nn640c {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
}
.c-1u4dhw5 {
  color: #ee2e24;
}
.c-1y9junu {
  padding-bottom: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  position: relative;
  outline: none;
}
.c-1lxcwxd {
  font-size: 16px;
  font-weight: 600;
  color: #ee2e24;
  outline: 0;
  pointer-events: auto;
  font-size: 14px;
}

.bed-seating-price {
  height: 50px;
  width: 100%;
 
 padding:0 8px;

 border-bottom: solid 1px #f0f0f0; 
  margin-top: 20px;
 
}
.bed-seating-price ul{
  display: flex;
  width: 100%;
align-items: center;
justify-content: space-between;
height: 100%;
}
.bed-seating-price ul li{
  display: flex;
  width: 33%;
align-items: center;

}
.bed-seating-price ul li img{
  width:30px;
}
.bed-seating-price ul li span{
 /* color: white; */
font-size: 14px;
font-weight: 600;
padding:0px 5px;


}
.seat-avilability-common-card {
 height: 55px;
  width: 100%;
 background:#15124a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:0px 5px;
  border-radius: 5px 5px 0px 0px;
 
}
.seat-avilability-common-card h4 {
  color: white;
font-size: 22px;
font-weight: 600;
}
.seat-avilability-common-card-box{
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px 5px 0px 0px;
}
ul.hostel-all-rooms-availability {
  /* margin-left: 50px;s */
  display: flex;
  width: 100%;
}

ul.hostel-all-rooms-availability li {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
ul.right-section-ul-li {
  position: relative;
  right: 25px;
}
.btn-enq {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  outline: none;
  font-weight: 600;
  height: 45px;
  width: 150px;
  border: none;
  text-decoration: none;
  background-image: linear-gradient(115deg,#4fcf70,#fad648,#a767e5,#12bcfe,#44ce7b);
}

.btn-enq:hover {
  animation: rotate 0.4s linear infinite;
}

@keyframes rotate {
  100% {
    filter: hue-rotate(-360deg)
  }
}

.btn-enq span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 142px;
  background: #222222;
  font-size: 18px;
}
@media only screen and (max-width: 600px) {
  .bed-seating-price ul{
    display: flex;
    flex-wrap: wrap !important;
  }
  .bed-seating-price ul li {
  width: 50%;
  }
  .bed-seating-price {
    height: 76px;
  }
  ul.hostel-all-rooms-availability {
    position: relative;
    right: 18px;
  }
}
