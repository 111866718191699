.stiky_header_menu {
    position: fixed;
    bottom: 0;
    left: auto;
    right: auto;
    width: 100%;
    text-align: center;
  }
  .stiky_header_menu {
    position: fixed;
    bottom: 0;
    left: auto;
    right: auto;
    width: 100%;
    text-align: center;
    z-index: 9999;
    background: #fff;
  }
  .stiky_header_menu span.nav-icon {
    display: block;
  }
  .stiky_header_menu {
    box-shadow: rgb(0 0 0 / 10%) 0px -1px 13px;
    padding-bottom: 10px;
    padding-top: 10px;
    color: #717171;
    padding-left: 5px;
    padding-right: 20px;
  }
  span.sp {
    font-size: 12px;
    color: #15124b!important;
    font-weight: 600!important;
}
  .nav-icon svg {
    font-size: 26px;
    margin-left: 5px;
    color: #15124b;
}
/* .active, .sticky-link{
 color: red;
} */