.image-caraousel {
  display: flex;
  height: 320px;
  margin: 0.5%;
}
.left-img-slider {
  width: 80%;
  cursor: pointer;
  position: relative;
}
.left-img-slider img {
  width: 100%;
  height: 100%;
  margin: 0.5%;
}
.right-img-slider {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: 0.5%;
  cursor: pointer;
}
.right-img-slider img {
  width: 100%;
  height: 20%;
  margin: 0.5%;
}
button.img-carousel-prev.btn {
  position: absolute;
  background: rgba(128, 128, 128, 0.5);
  /* background-color: white; */
  height: 30px;
  width: 30px;
  border-radius: 50%;
  left: 5px;
  top: 45%;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
button.img-carousel-next.btn {
  position: absolute;
  background: rgba(128, 128, 128, 0.5);
  /* background-color: white; */
  height: 30px;
  width: 30px;
  border-radius: 50%;
  right: 0px;
  top: 45%;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
button.img-carousel-prev.btn svg {
  font-size: 14px;
  position: relative;
  right: 4px;
  bottom: 5px;
}
button.img-carousel-next.btn svg {
  font-size: 14px;
  position: relative;

  right: 3px;

  bottom: 5px;
}
button.img-carousel-prev.btn:active {
  outline: none;
  border: none;
  background: rgba(128, 128, 128, 0.5);
}
button.img-carousel-next.btn:active {
  outline: none;
  border: none;
  background: rgba(128, 128, 128, 0.5);
}
