.down {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 30px;
  height: 50px;
  cursor: pointer;
}
.down:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.down:hover.down img {
  transform: rotate(180deg);
  transition: all 0.3s;
}
.down p {
  font-weight: 500;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: -0.015em;
  position: relative;
  top: 7px;
  color: #15124b;
}
.down img {
  margin-left: 8px;
  margin-right: 0px;
  height: 24px;
  width: 18px;
}
.dropdown-sub-menu {
  position: absolute;
  top: 145px;
  padding: 10px 35px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08), 0 6px 20px 0 rgba(0, 0, 0, 0.08);
  z-index: 9999;
  background-color: #ffffff;
  text-align: left;
}
.dropdown-sub-menu p {
  margin: 15px;
  cursor: pointer;
  font-size: 16px!important;
  font-weight: 500;
}
/* .dropdown p:nth-of-type(1) {
  font-weight: 500;
} */
.dropdown-sub-menu p:hover {
  color: #15124b;
  transition: all 0.15s;
}