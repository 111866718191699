.hostel-screens {
  position: absolute;
  width: 100%;
  /* overflow: hidden; */
}
.hostel-screens-top {
  position: fixed;
  z-index: 999999999;
  background-color: #fff;
  width: 100%;
  height: 70px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.hostel-screen-main {
  position: relative;
  margin-top: 5px;
  width: 100%;
}
.main-section-hostel{
  display: flex;
  width: 100%;
}
.left-section-hostel {
  display: flex;
  flex-direction: column;
  padding: 2%;
  width: 25%;
  position: sticky;
  position: -webkit-sticky;
  border-right: 2px solid grey;
}
.right-section-hostel{
  display: flex;
  flex-direction: column;
  position: relative;
  width: 74%;
  padding: 2%;
}
@media only screen and (max-width: 767px) {
  .left-section-hostel{
    display: none;
  }
  .covid-box {
    display: none;
  }
  .right-section-hostel{
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1180px) {
  .left-section-hostel{
    display: none;
  }
  .right-section-hostel{
    width:100%;
  }
}