.refund-policy-logo img {
  height: 100%;
}
.refund-policy-logo {
  height: 85px;
}
.refund-policy-heading {
  height: 150px;
  width: 100%;
  padding: 20px 0px;
  background-image: url(./RefundPolicyImage/refundpolicy.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.refund-policy-heading-text {
  text-align: center;
  color: white;
  font-size: 40px;
  font-weight: 600;
}
p.refund-policy-sub-text {
  color: white;
  font-size: 18px;
  text-align: center;
}
p.refund-policy-sub-text span a {
  text-decoration: none;
  color: white;
}
.refund-policy-text-box {
  width: 100%;
  min-height: 600px;
text-align: justify;
  padding: 10px 0px;
}
.refund-policy-para-text1 {
  color: black;
  display: block;
  line-height: 24px;
  font-weight: 400 !important;
  margin: 0 0 20px;
  font-size: 16px;
  text-align: justify;

}
.refund-policy-box-heading{
    font-size: 26px;
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
    color: #15124b;
    margin: 0px 0px 14px 0px;
    padding: 0px;
}
.refund-policy-para-list{
    margin-top: 10px;
    list-style: revert!important;
    margin-left: 20px;
}