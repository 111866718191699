label.check-avilability-label {
  text-align: left;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
}
span.validate-avalilibility-star-requied {
  font-size: 18px;
  position: relative;
  top: 2px;
  color: red;
}
input.check-availibility-input {
  height: 42px;

  width: 100%;
  border: 1px solid #15124b;
  border-radius: 5px 5px 5px 5px;
  padding: 0px 10px;
  margin-top: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  cursor: pointer;
}
input.check-availibility-input:focus-visible {
  outline: none;
  border-radius: 3px 0px 0px 3px;
}
button.check-availibility-button {
  padding: 11px 5px;
  border: 1px solid #15124b;
  color: white;
  background: lightgray;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 600;
  margin-top: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width:100%;
}
@media only screen and (max-width: 600px) {
  
}