.my-booking-card  {
    width: 378px;
    border-radius: 2px;
    box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.1);
    border: solid 1px #d6d6d6;
    margin-right: 24px;
}
.my-booking-card-box  {
    padding: 34px 24px;
}
.c-17anid6-icon {
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    /* background: rgb(0, 0, 0); */
    border-radius: 50%;
    width: 42px;
    height: 42px;
    float: left;
}
.c-17anid6-icon img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.my-booking-wizard-heading {
    display: flex;
}
.logo-wizard {
  
    width: 100%;
    display: flex;
}
.c-nfd45z-text {
    margin: 10px 0px 12px;
    font-size: 26px;
    font-weight: bold;
    line-height: 1.23;
    color: rgb(51, 51, 51);
}
.c-ayr394-text > div > div {
    padding-bottom: 5px;
}
.c-ayr394-text > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 1.56;
    color: rgb(34, 34, 34);
    text-align: justify;
}
.c-fj084q-btn {
    margin: 13px 0px 2px;
}
.c-fj084q-btn > button {
    color: rgb(255, 255, 255);
    border-radius: 4px;
    background-color: rgb(26, 182, 79);
    border: none;
    height: 44px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.44px;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: left;
}


