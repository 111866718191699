.world-wide {
  background: #f4fcfe;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
}
.world-wide-left-area {
  background-image: url(./CityImges/backgroungmap.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 410px;
  /* background-color: red; */
  width: 100%;
  position: relative;
}

.hostelimg-country-section1 {
  position: absolute !important;
  left: 200px;
  height: 130px;
  bottom: 60px;
  width: 110px;
}
.hostelExpanding__countryImage {
  cursor: pointer;
  width: 80px;
  height: 85px;
  border-radius: 4px;
  position: relative;
  transform: scale(0);
  transition: all 0.2s ease-in-out;
}
.hostelExpanding__countryImage.active {
  transform: scale(1);
}
.city-img-box {
  color: rgba(0, 0, 0, 0.54);
  background: transparent;
  animation: auto ease 0s 1 normal none running none;
}
.city-img-box img {
  width: 100%;
}
.city-img1 {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  vertical-align: middle;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  object-fit: covers;
}

.city-name-txt-home1 {
  display: none;
  background-color: white;
  padding: 3px;
  border-radius: 2px;
  margin-top: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  color: #15124b;
  font-size: 16px;
  font-weight: 600;
}
.city-img-box:hover + .city-name-txt-home1 {
  display: block;
}
.hostelimg-country-section2 {
  position: absolute;
  top: 10px;
  left: 550px;
}
.hostelimg-country-section3 {
  position: absolute;

  left: 540px;
  bottom: 50px;
}
.hostelimg-country-section4 {
  position: absolute;

  top: 8px;
  left: 300px;
}
.hostelimg-country-section5 {
  left: 95px;
  position: absolute;
  top: 100px;
}
.hostelimg-country-section6 {
  position: relative;
  top: 160px;
  left: 370px;
}
.world-wide-right-section {
  height: 410px;
  padding: 0px 50px;
  width: 100%;
}

.d-heading {
  font-size: 26px;
  font-weight: 700;
  color: #333;
  margin-top: 20px;
  text-align: left;
}
.hostelExpanding__text {
  margin-top: 0;
}
.u-extraLineHeight {
  line-height: 1.5;
}
.d-text16 {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  text-align: left;
}

.hostelExpanding__insights {
  margin-top: 20px;
  text-align: left;
  display: flex;
}
.hostelExpanding__insightItemWrap:first-child {
  padding-left: 0;
  border-left: 0;
  display: flex;
}
.hostelExpanding__insightItemWrap {
  display: inline-block;
  padding: 0 30px;
}
.d-text14 {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}
.hostelExpanding__insightItem span {
  display: block;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.87);
  transform: skewX(3deg);
  margin-right: 5px;
}
.is-fontBold {
  font-weight: 600;
}
.world-wide-right-inner-box {
  width: 100%;

  margin: 0px auto;
  padding: 10px 50px !important;
  padding: 10px;
}
p.is-font-city-bold {
  font-size: 18px;
  color: #15124b;
  font-weight: 500;
  font-family: sans-serif;
}
.slash {
  margin-left: 20px;
  width: 20px;
  height: 65px;
  margin-top: 14px;
  border-right: 1px solid grey;
  transform: skewX(-22deg);
}
.hostelExpanding__cityList {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 40px 0;
  list-style: none;
}
.hostelExpanding__city {
  display: block;
  margin: 20px 0;
  width: 33.33%;
  word-wrap: break-word;
}
.hostelExpanding__cityColor {
  display: inline-block;

  border-radius: 50%;
  width: 8px;
  height: 8px;
  overflow: hidden;
  margin-right: 12px;
}

.hostelExpanding__cityLink a {
  text-decoration: none;
  color: #15124b;
}
.city1 {
  background-color: rgb(26, 182, 79) !important;
}
.city2 {
  background-color: rgb(255, 127, 123);
}
.city3 {
  background-color: rgb(245, 166, 35);
}
.city4 {
  background-color: rgb(95, 214, 242);
}
.city5 {
  background-color: rgb(254, 148, 241);
}
.city6 {
  background-color: rgb(126, 137, 228);
}

.carousel-wrap {
  margin: 90px auto;
  padding: 0 5%;
  width: 80%;
  position: relative;
}

/* fix blank or flashing items on carousel */
.item {
  position: relative;
  z-index: 100;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* end fix */
.owl-nav > div {
  margin-top: -26px;
  position: absolute;
  top: 50%;
  color: #cdcbcd;
}

.owl-nav i {
  font-size: 52px;
}

.owl-nav .owl-prev {
  left: -30px;
}

.owl-nav .owl-next {
  right: -30px;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
  display: none;
}
.item.city-name h4 {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}
h3.slider-heading-text {
  text-align: left;
  font-size: 22px;
  font-weight: 700;
}
.carousel-cont {
  display: none;
}
@media only screen and (max-width: 600px) {
  .carousel-cont {
    display: block;
  }
  .item-city-img-box {
    height: 110px !important;
  }
  .item-city-img-box img {
    height: 100%;
    width: 100%;
    padding: 5px;
  }
  .item.city-name{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
  }
  .item.city-name h4 {
    padding-bottom:10px;
} .item.city-name h4 a{
 color: #15124b;
 font-weight: 600;
}
.all-hide-city{
  display: none;
}
}
@media only screen and (min-width: 601px) and (max-width: 767px) {
  .carousel-cont {
    display: block;
  }
  .item-city-img-box {
    height: 110px !important;
  }
  .item-city-img-box img {
    height: 100%;
    width: 100%;
    padding: 5px;
  }
  .item.city-name{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
  }
  .item.city-name h4 {
    padding-bottom:10px;
} .item.city-name h4 a{
 color: #15124b;
 font-weight: 600;
}
  .all-hide-city{
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .carousel-cont {
    display: block;
  }
  .all-hide-city{
    display: none;
  }
  .item.city-name{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
  }
  .item-city-img-box {
    height: 150px !important;
  }
  .item-city-img-box img {
    height: 100%;
    width: 100%;
    padding: 5px;
  }
  .item.city-name{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
  }
  .item.city-name h4 {
    padding-bottom:10px;
}
 .item.city-name h4 a{
 color: #15124b;
 font-weight: 600;
}
}
