.covid-box{
    background-color: #d79a12;
  color: #000000;
  width: 100%;
  height: 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  /* identical to box height */
  text-align: center;
  /* margin-top: 75px;
  position: absolute; */
}
@media only screen and (min-width: 768px) and (max-width: 1180px) {
  .covid-box{display: none;}
}
