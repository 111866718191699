.hostel-screen-section-box{
width: 100%;
overflow: hidden;
}
/* .hostel-details-screens {
  height: 100vh;
} */
.hostel-container-layout {
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.hostel-details-sticky{ 
    height: 70px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.r9c3xt {
    width: 100%;
    min-height: 100vh;
    background: #fff;
    position: relative;
}