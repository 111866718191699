.rating-box-detais {
  width: 100%;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  padding-bottom: 20px;
}
.rating-box-detais h4 {
  font-size: 21px;
  color: #15124b;
  font-weight: 700;
  margin: 15px 0;
  width: 100%;
  text-align: left;
  padding: 15px 14px 12px 10px;
  border-bottom: 1.5px solid hsla(0, 0%, 88.6%, 0.4);
}
.rating-box-detais h5 {
  /* font-family: SF-Regular; */
  font-size: 18px;

  text-align: left;
  padding-left: 20px;
  font-weight: 700;
}
.Reviews_reviews__content__left__star__Tpib0 {
  align-items: center;
  column-gap: 5px;
  display: flex;
  padding-left: 15px;
}
.Reviews_reviews__content__left__star__Tpib0 i {
  font-size: 35px;
}
.rating-box-detais p {
  font-size: 18px;
  text-align: left;
  padding-left: 20px;
  font-weight: 700;
}
.discript-textarea{
    display: flex;
    padding-left: 20px;
}
.discript-textarea textarea{
    border: 2px solid #707070;
    font-size: 16px;
    min-height: 100px;
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    width: 300px;
    border-radius: 5px;

}
.rainbows-hovers {
    font-size: 16px;
    font-weight: 700;
    color: #ff7576;
    background-color: #2B3044;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 12px 24px;
    position: relative;
    line-height: 24px;
    border-radius: 9px;
    box-shadow: 0px 1px 2px #2B3044,
      0px 4px 16px #2B3044;
    transform-style: preserve-3d;
    transform: scale(var(--s, 1)) perspective(600px)
      rotateX(var(--rx, 0deg)) rotateY(var(--ry, 0deg));
    perspective: 600px;
    transition: transform 0.1s;
    display: flex;
    margin-left: 20px;
    width: 300px;
    justify-content: center;
  }
  
  .span-d {
    background: linear-gradient(
        90deg,
        #866ee7,
        #ea60da,
        #ed8f57,
        #fbd41d,
        #2cca91
      );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    display: block;
    text-align: center;
    font-weight: 700;
  }
  
  .rainbows-hovers:active {
    transition: 0.3s;
    transform: scale(0.93);
  }