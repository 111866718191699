
.social-link {
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    border-radius: 50%;
    transition: all 0.3s;
    font-size: 0.9rem;
  }
  
  .social-link:hover,
  .social-link:focus {
    background: #ddd;
    text-decoration: none;
    color: #555;
  }
  
  .progress {
    height: 10px;
  }
  .blog-cards-img-box {
    height: 230px;
   background-color: red;
}
.blog-cards-img-box img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.blog-cards-title {
    min-height: 70px;
    text-align: left;
    margin-top: 5px;
}