@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.user-profile-screen{
    width: 100%;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
}

.main-body {
    padding: 15px;
}
.card {
    /* box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06); */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}
.h-100 {
    height: 100%!important;
}
.shadow-none {
    box-shadow: none!important;
}
.document-image-student{
    width: 100%;
    min-height: 280px;
    padding: 5px;
}
.user-profile-document-text{
text-align: left;
font-size: 1rem;
font-weight: 600;
padding: 0px 5px;
position: relative;
top:7px;
}
.user-profile-document-image{
    height: 100%;
    width: 100%;
}
.user-profile-document-image-box{
height: 180px;
width: 100%;
}
.user-profile-document-image-box p{
font-size: 1rem;
font-weight: 600;
padding: 0px;
margin: 0px;
}
button.btn.btn-outline-primary svg {
    font-size: 30px;
}