.form_main {
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  padding: 30px 30px 30px 30px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.062);
  position: relative;
  overflow: hidden;
}

.form_main::before {
  position: absolute;
  content: "";
  width: 300px;
  height: 300px;
  background-color: rgb(209, 193, 255);
  transform: rotate(45deg);
  left: -180px;
  bottom: 30px;
  z-index: 1;
  border-radius: 30px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.082);
}

.heading {
  font-size: 2em;
  color: #2e2e2e;
  font-weight: 700;
  margin: 5px 0 10px 0;
  z-index: 2;
}

.inputContainer {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.inputIcon {
  position: absolute;
  left: 3px;
}

.inputField {
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgb(173, 173, 173);
  margin: 10px 0;
  color: black;
  font-size: .8em;
  font-weight: 500;
  box-sizing: border-box;
  padding-left: 30px;
}

.inputField:focus {
  outline: none;
  border-bottom: 2px solid rgb(199, 114, 255);
}

.inputField::placeholder {
  color: rgb(80, 80, 80);
  font-size: 1em;
  font-weight: 500;
}

#button {
  z-index: 2;
  position: relative;
  width: 100%;
  border: none;
  background-color: rgb(162, 104, 255);
  height: 30px;
  color: white;
  font-size: .8em;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 10px;
  cursor: pointer;
}

#button:hover {
  background-color: rgb(126, 84, 255);
}

.forgotLink {
  z-index: 2;
  font-size: .7em;
  font-weight: 500;
  color: rgb(44, 24, 128);
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 20px;
}



.login-signup {
  min-height: 600px;

  background-image: url(./MediaImage/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.login-signup .container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  /* background: linear-gradient(to bottom, rgb(6, 108, 100), rgb(14, 48, 122)); */
  width: 80%;
  min-height: 500px;
  /* margin: 10% auto; */
  border-radius: 5px;
  position: relative;
}

.content-holder {
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: lighter;
  letter-spacing: 2px;
  margin-top: 15%;
  padding: 50px;
}

.content-holder h2 {
  font-size: 34px;
  margin: 20px auto;
  color: white;
}

.content-holder p {
  margin: 30px auto;
}

.content-holder button {
  border: none;
  font-size: 15px;
  padding: 10px;
  border-radius: 6px;
  background-color: white;
  width: 150px;
  margin: 20px auto;
}
.box-2 {
  background-color: white;
  margin: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.login-form-container {
  text-align: center;
  /* margin-top: 10%; */
}

.login-form-container h1 {
  color: white;
  font-size: 24px;
  padding: 20px;
}

.input-field {
  box-sizing: border-box;
  font-size: 14px;
  padding: 10px;
  border-radius: 7px;
  border: 1px solid rgb(168, 168, 168);
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  /* box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12); */
  width: 100%;
  outline: none;
  min-height: 45px;
  float: left;
}

.login-button {
  box-sizing: border-box;
  color: white;
  font-size: 18px;
  padding: 13px;
  border-radius: 7px;
  border: none;
  margin-top: 25px;
  outline: none;
  font-weight: 700;
  float: left;
  width: 100%;

  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.login-form-cont-head {
  min-height: 50px;
  width: 100%;
  background-color: #15124b;
}
.login-form-cont-main {
  min-height: 300px;
  /* background: lightblue; */
  width: 80%;
  margin: auto;
  padding: 20px;
}

.signup-form-container {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  text-align: center;
}

.login-form-cont-main p {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}
.signup-form-container h1 {
  color: black;
  font-size: 24px;
  padding: 20px;
}

.signup-button {
  box-sizing: border-box;
  color: white;
  font-size: 18px;
  padding: 13px;
  border-radius: 7px;
  border: none;
  margin-top: 25px;
  outline: none;
  font-weight: 700;
  float: left;
  width: 90%;
  background-color: #15124b;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
button.resend-otp-otp {
  border: none;
  background: none;
  
  position: relative;
  
  left: 165px;
  top: 10px;
  font-size: 16px;
  font-weight: 600;
}
@media only screen and (max-width: 600px) {
  .login-signup .container {
     grid-template-columns: 1fr;
     width: 100%;
  }
  .content-holder{
    margin-top: 0px;
    padding:5px;
  }
  .content-holder h2 {
    font-size: 24px;
  }
  .content-holder h6 {
    text-align: justify;
  }
  .box-2{
    margin: 0px;
    padding-bottom: 40px;
  }
  button.resend-otp-otp{
    left:90px;
  }
  .signup-button {
    width: 100%;
  }
  .login-form-cont-main{
    min-height: 100px;
    width: 100%;
  }
  .input-field {
    width: 100%;
  }
  .login-button {
    width:100%;
  }
  .login-form-cont-main p{
    font-size: 18px;
  }
}