.section-padding {
    padding: 120px 0;
    /* background-color: greenyellow; */
}
.news2-sidebar .widget {
    background: #f8f5f0;
    padding: 30px;
    margin-bottom: 30px;
    overflow: hidden;
}
img.mb-30.img1-blog {
    width: 100%;
}
.news2-sidebar .widget-title {
    margin-bottom: 20px;
}
.news2-sidebar .widget-title h6 {
    padding-bottom: 5px;
    border-bottom: 1px solid #eee3d0;
    font-size: 24px;
    line-height: 1.75em;
    margin-bottom: 15px;
    color: #222;
}
.news2-sidebar .widget ul {
    margin-bottom: 0;
    padding: 0;
}
.news2-sidebar .widget .recent li {
    display: block;
    overflow: hidden;
}
.news2-sidebar .widget ul li {
    margin-bottom: 15px;
    color: #666;
    font-size: 15px;
    line-height: 1.5em;
    text-align: left
}
.news2-sidebar .widget .recent .thum {
    width: 90px;
    overflow: hidden;
    float: left;
}
.news2-sidebar .widget .recent a {
    display: block;
    margin-left: 105px;
}
.news2-sidebar .widget ul li a {
    color: #666;
}
.news2-sidebar .widget ul li span {
    font-size: 12px;
    margin-right: 10px;
}

.ti-angle-right::before {
    content: "\276F";
}
.blog-details-main-box-discription{
width: 100%;
}
.blog-details-main-box-discription h2{
    text-align: left;
    }
    .blog-details-main-box-discription p{
        text-align: left;
        }