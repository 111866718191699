.booking-nave-sticky {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 90px;
}
.sticky {
  position: absolute;
  top: 0px;
}
.nav-sticky-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 5px;
  left: 240px;
  border: 1px solid lightgray;
  border-radius: 5px;
}
.booking-logo-box {
  /* height: 70px; */
  height: 100%;
  width: 350px;

  /* background: greenyellow; */
}
img.booking-logo {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 10px;
}
