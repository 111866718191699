.booking-right-section-container {
  min-width: 376px;
  max-width: 450px;
  border-radius: 4px;
  border: solid 1px rgba(122, 122, 122, 0.17);
  box-shadow: 0 2px 26px 0 rgba(125, 125, 125, 0.1);
  padding: 24px;
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
}
.bkng-top-section {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.c-5hicrt {
  margin-right: 12px;
}
.c-6a3y35 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  width: 280px;
  margin-bottom: 8px;
  text-align: left;
}
.c-zpzivj {
  opacity: 0.4;
  font-size: 14px;
  line-height: 1.71;
  color: rgb(12, 10, 21);
  margin-bottom: 8px;
}
.bkng-hostel-rating {
  position: relative;
  display: flex;
}
.hostelRating__wrapper {
  outline: none;
  display: flex;
  align-content: center;
  align-items: center;
}
.hostelRating__rating--clickable {
  cursor: pointer;
}
.hostelRating__rating--fair {
  background-color: #b4da55;
}
.hostelRating__rating {
  border-radius: 2px;
  padding: 3px 6px;
  margin-right: 9px;
  background-color: #52b520;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
}
.is-fontBold {
  font-weight: 600;
}
.hostelRating__star {
  line-height: 14px;
  margin-left: 7px;
  position: relative;
  top: 1px;
}
.c-1in37i9 {
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.hostelRating__ratingSummary {
  font-size: 12px;
  color: #222;
}
.hostelRating__rating--clickable {
  cursor: pointer;
}
.hostelRating__dot {
  margin: 0 4px;
  color: #222;
}
.hotelRating__ratingSummary {
  font-size: 12px;
  color: #222;
}
.bkng-time {
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
  color: rgb(12, 10, 21);
  margin-top: 8px;
  text-align: left;
}
.bking-img-right {
  border-radius: 4px;
  box-shadow: rgba(125, 125, 125, 0.1) 0px 2px 6px 0px;
  width: 79px;
  height: 62px;
  opacity: 1.2;
  border: 1px solid rgb(151, 151, 151);
  background-color: rgb(216, 216, 216);
}
.c-e2r6u2 {
  color: rgba(0, 0, 0, 0.54);
  height: 100%;
  width: 100%;
  background: rgb(243, 245, 247);
  animation: auto ease 0s 1 normal none running none;
}
.c-e2r6u2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.book-bkng-details {
  border-radius: 4px;
  margin: 18px 0px;
}
.bkng-dtlls-box {
  position: relative;
}
.c-g4aqpa {
  box-shadow: none;
  cursor: default;
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-left-color: initial;
  border-image: initial;
  border-bottom-style: solid;
  border-bottom-color: rgb(240, 240, 240);
}

.c-gx0lhm {
  margin-bottom: 0px;
}

.c-1thcb3a {
  display: flex;
  margin-top: 16px;
  width: 100%;
  box-shadow: rgba(125, 125, 125, 0.1) 0px 2px 6px 0px;
  cursor: pointer;
  border: 1px solid rgb(240, 240, 240);
}

.c-9whsf3 {
  max-width: 100%;
}
.c-1pzykbe {
  display: flex;
  border-radius: 4px 0px 0px 4px;
  padding: 0px;
  -webkit-box-align: center;
  align-items: center;
}
.c-1a5t1qn {
  margin-top: 2px;
  height: 30px;
  width: 40px;
}
.c-1a5t1qn svg {
    width: 24px;
    height: 22px;
}
.hostel-row.oyo-row--no-spacing {
    padding: 0;
}
.datePickerDesktop--hostel {
    border: 0;
    width: 100%;
    margin: 0 12px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 14px 0!important;
}
.datePickerDesktop {
    position: relative;
    color: rgba(0,0,0,.87);
    background-color: transparent;
    border: none;
    transition: .2s;
    box-sizing: border-box;
    padding: 10px!important;
    border-right: 1px solid rgba(0,0,0,.07)!important;
}

.hostel-row {
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    margin: 0 auto;
}
.hostel-row.hostel-row--no-spacing>.hostel-cell {
    margin: 0;
}
.datePickerDesktop--hostel .datePickerDesktop__checkInOut {
    font-size: 14px;
    color: #0c0a15;
    line-height: 1.71;
    font-weight: 600;
    background: #fff;
    padding: 0;
    display: block;
}


.u-textEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.datePickerDesktop__checkInOut {
    padding-left: 16px;
    display: flex;
    align-items: center;
    color: rgba(0,0,0,.87);
    user-select: none;
}

.u-textEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.d-text16 {
    font-size: 16px;
}
.d-text16, .d-text18 {
    color: rgba(0,0,0,.7);
}
.datePickerDesktop .datePickerDesktop__checkInOutText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.datePickerDesktop__arrowWrapper--hostel {
    margin: 0 4px;
}
.datePickerDesktop__arrowWrapper {
    display: inline-block;
    margin: 0 10px;
}
.datePickerDesktop .datePickerDesktop__checkInOutText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.datePickerDesktop--hostel .datePickerDesktop__checkInOut {
    font-size: 14px;
    color: #0c0a15;
    line-height: 1.71;
    font-weight: 600;
    background: #fff;
    padding: 0;
    display: block;
}
.c-107tt68 {
    border: 0px;
}

.c-14mt3w6 {
    display: flex;
    padding: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    position: relative;
}
.guestRoomPicker--hostel {
    border: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 14px 0!important;
}
.guestRoomPicker {
    position: relative;
    color: rgba(0,0,0,.87);
    background-color: transparent;
    border: none;
    transition: .2s;
    box-sizing: border-box;
    padding: 10px!important;
}

.hostel-row {
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    margin: 0 auto;
}
.u-textEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.guestRoomPicker__guestRoomCount {
    padding-left: 16px;
    display: flex;
    align-items: center;
    color: rgba(0,0,0,.87);
    user-select: none;
}
.c-abzw2p {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
}
.bkng-classic-box {
    display: flex;
    padding: 12px 0px;
    border-radius: 4px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative;
    width: 100%;
    max-width: 100%;
    margin-top: 0px;
    box-shadow: none;
    border: 0px;
}
.c-1953uyt {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    display: flex;
    margin: 0px;
}
.c-1o86iao svg {
    height: 100%;
    width: 100%;
}
.c-1o86iao {
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 20px;
    width: 20px;
}
.bkng-room-details {
    margin-left: 10px;
}
.c-18axi1k {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin: 12px 0px;
}
.c-6xf37g {
    font-size: 14px;
    line-height: 2;
    color: rgb(34, 34, 34);
}
.c-tvy9fi {
    font-size: 14px;
    font-weight: 600;
    line-height: 2;
    color: rgb(34, 34, 34);
}
.c-bjdcis {
    border: 1px solid rgb(240, 240, 240);
    margin: 24px 0px;
}
.c-dgqod7 {
    color: rgb(34, 34, 34);
    font-size: 16px;
    line-height: 1.56;
    width: 70%;
    text-align: left;
}
.c-m8gzcb {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    opacity: 0.54;
}
.c-11tk0uk {
    color: rgb(34, 34, 34);
    font-size: 22px;
    font-weight: bold;
    line-height: 1.33;
}
button.book-now-pay-btn {
  width: 100%;
  font-size: 20px;
  text-align: center;
  padding: 10px 0px;
  font-weight: 700;
  border: none!important;
  outline: none;
  background: #15124b;
  color: #fff;
}