.homepage-slide-main-banner {
  min-height: 200px;
  padding: 0 10px;
  width: 100%;
  margin: 5px auto 20px;
  overflow: hidden;
 
}
.material-shadow-card-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
/* 
.image-main-banner {
  width: 100%;
  height: 300px;
  margin: auto;
  display: block;
 
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  position: relative;
  top:2px;
}
.img-cover-no-stretch-slider {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  display: block;
}

.slider-img-blank-div {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  margin: 0;
}
.slider-img-main-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
} */
/* 
.homepage-slide-main-banner .slick-next {
  right: 10px!important;
  z-index: 99;
  height: 30px!important;
  width: 30px!important;
  background-color: #e1e1e1!important;
  border-radius: 50%;
}
.homepage-slide-main-banner .slick-prev{
  left: 10px!important;
  z-index: 99!important;
  height: 30px!important;
  width: 30px!important;
  background-color: #e1e1e1!important;
  border-radius: 50%;
}
.homepage-slide-main-banner .slick-prev:before, .slick-next:before {
position: relative;
top:6px;
color: black!important;
} */