.hostelinfo-section {
  display: flex;
  margin: 0;
  padding: 1% 0;
  border-bottom: 1px solid #e6e6e6;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 10px;
}
.left-carousel {
  width: 60%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.right-careousel-info {
  display: flex;
  width: 90%;
  padding: 1%;
  margin:0% 1%;
  background: azure;
}
.hostel-info-block {
 
  width: 100% !important;
 
}
.hostel-info-items-head {
  padding: 0 0 0 16px !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  /* height: 100%; */
}
.info-head p {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #424242;
}
.info-head h6 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 5px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #424242;
}
.info-rating {
  position: absolute;
  width: 46px;
  height: 20px;
  background: #5ecc37;
  border-radius: 2px;
  border-radius: 5%;
  color: #ffffff;
  padding: 0 0.3%;
  margin: 2px 0;
}
.info-rated {
  margin-left: 52px;
}
.commnon-info-div {
  flex: 2;
}
.listingHotelDescription__contentWrapper {
  display: flex;
  align-items: flex-start;
}
.listingHotelDescription__contentWrapper--left {
  width: calc(100% - 145px);
  padding-right: 20px;
  box-sizing: border-box;
}
.u-width100 {
  width: 100% !important;
}
.c-nn640c {
  -webkit-text-decoration: none;
  text-decoration: none !important;
  color: inherit;
}
.listingHotelDescription__hotelName {
  font-size: 20px;
  font-weight: 700;
  /* line-height: 24px; */
  color: #222;
  text-align: left;
}
.d-textEllipsis {
  white-space: nowrap;
}
.d-line--clamp-7,
.d-textEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
.listingHotelDescription__hotelAddress {
  padding-top: 2px;
  display: flex;
  font-size: 16px;
  font-weight: 500;
}
.d-body-lg,
.d-body-sm {
  color: #222;
  font-weight: 400;
}
.d-body-lg {
  font-size: 16px;
  line-height: 25px;
}
.u-line--clamp-2 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical !important;
}
.listingHotelDescription__hotelAddressMap {
  white-space: nowrap;
}
.listingHotelDescription__distanceText,
.listingHotelDescription__dot {
  margin: 0 0px;
}
.listingHotelDescription__contentWrapper--right {
  width: 145px;
  min-width: 145px;
}
.c-1m75bgc {
  display: flex;
  padding: 10px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
  color: rgb(238, 46, 36);
}
.c-1i6ioo7 {
  width: 12px;
}
.c-1sugwtq {
  padding-left: 0px;
}
.hostelRating {
  position: relative;
  display: flex;
  margin-top: 20px;
  /* background-color: #0052c4; */
}
.hostelRating__wrapper {
  outline: none;
  display: flex;
  align-content: center;
  align-items: center;
}
.hostelRating__rating--clickable {
  cursor: pointer;
}
.hostelRating__rating--good {
  background-color: #15124b !important;
}
.hostelRating__rating {
  border-radius: 2px;
  padding: 3px 6px;
  margin-right: 9px;
  background-color: #15124b !important;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
}
.is-fontBold {
  font-weight: 600;
}
.hostelRating__ratingSummary {
  font-size: 12px;
  color: #222;
}
.hostelRating__rating--clickable {
  cursor: pointer;
}
.hostelRating__dot {
  margin: 0 4px;
  color: #222;
}
.hostelRating__ratingSummary {
  font-size: 12px;
  color: #222;
}
.amenityWrapper {
  display: flex;
  margin-top: 6px;
  flex-wrap: wrap;
}
.amenityWrapper__amenity {
  display: flex;
  align-items: center;
  width: 25%;
  padding-right: 24px;
}
.c-1in37i9 {
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.amenityWrapper__icon {
  margin-right: 5px;
  /* width: 18px;
    height: 18px; */
  font-size: 22px;
}
.d-body-sm {
  font-size: 14px;
  line-height: 28px;
  color: #222;
  font-weight: 400;
  position: relative;
  bottom: 1px;
}
.listingHostelDescription__labels {
  display: inline-flex;
  margin-top: 6px;
}
.c-hpocxd {
  display: inline-block;
  border-radius: 2px;
  white-space: nowrap;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  box-sizing: border-box;
  border: 1px solid #cecece;
  margin-right: 8px;
}
.c-8sjad0 {
  background-color: #212121;
  display: inline-block;
  padding: 4px 0 4px 5px;
  white-space: nowrap;
  margin: 1px !important;
  border-radius: 2px;
  overflow: hidden;
  font-size: 0;
  line-height: 18px;
}
.c-ztdfbn {
  height: 14px;
  width: 22px;
  display: inline-block;
  padding-right: 0px;

  margin-right: 0px !important;
}
.c-ztdfbn span {
  margin-right: 8px;
}
.c-1vsluwb {
  font-size: 11px;
  line-height: 24px;
  padding: 0 7px;
  font-weight: bold;
  -webkit-letter-spacing: 0.4px;
  -moz-letter-spacing: 0.4px;
  -ms-letter-spacing: 0.4px;
  letter-spacing: 0.4px;
  color: #212121;
}
.w-icon-default img {
  width: 20px;
  height: 20px;
}
.listingHostelDescription__priceBtn {
  width: 100%;
}
.hostel-row {
  align-items: stretch;
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
}
.hostel-row--no-spacing {
  padding: 15px 0px;
  width: 100%;
}
.listingPrice__numbers {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.listingPrice__finalPrice {
  margin-right: 8px;
  font-size: 24px;
  font-weight: 700;
  color: #ee2a24;
}
.listingPrice__slashedPrice {
  text-decoration: line-through;
  font-size: 16px;
  color: #6d787d;
  margin-right: 8px;
}
.listingPrice__percentage {
  font-size: 14px;
  font-weight: 600;
  color: #f5a623;
  line-height: 25px;
}
.listingPrice__perRoomNight {
  font-weight: 400;
  line-height: 1em;
  margin-top: 4px;
  text-align: left;
}
.listingPrice__perRoomNight,
.listingPrice__text {
  font-size: 12px;
  color: #6d787d;
}
.listingHostelDescription__btnWrapper {
  display: flex;
  align-content: center;
  justify-content: flex-end !important;
  /* margin-left: 100px; */
}
.c-1k6asfw {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 10px auto;
  border-radius: 2px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  font-family: "Inter";
}
.d-whiteButton {
  background-color: #fff;
  color: #222;
  border-radius: 2px !important;
  border: 1px solid #222;
}
.d-greenButton,
.d-whiteButton {
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 1.25em;
  text-align: center;
  outline: none;
  padding: 8px 16px !important;
  width: auto !important;
  margin: 0 !important;
  align-self: flex-end;
  white-space: nowrap;
  min-width: 140px;
  min-height: 43px;
}
button,
select {
  text-transform: none;
}
button,
input {
  overflow: visible;
}
button,
input,
optgroup,
select,
textarea {
  font-family: Inter, sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
.d-greenButton {
  background-color: #15124b;
  color: #fff;
  border-radius: 2px !important;
  border: 1px solid #15124b;
  margin-left: 50px !important;
}
.hostel-info-seat-price {
  padding: 10px 0px;
  /* background: forestgreen; */
  width: 100%;
  position: relative;
  top: 5px;
}
.hostel-info-seat-price ul {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px;
}
.hostel-info-seat-price ul li {
  display: flex;
}
.hostel-info-seat-price ul li img {
  width: 25px !important;
}
span.hostel-info-seat-price-text {
  font-weight: 500;
  padding: 0px 8px;
}
span.c-1in37i9.amenityWrapper__icon.bsplus {
  position: relative;
  left: 7px;
  color: red;
}
span.d-body-sm.d-textEllipsis.btn-view-all-hostel-info {
  font-weight: 700;
  color: #15124b;
}
.amenityWrapper__amenity.txt-underline:hover {
  text-decoration: underline;
}
