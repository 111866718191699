.seat-chart-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 20px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .seat-chart-modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 0px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    border-radius: 8px 8px 0px 0px !important;
    min-height: 500px;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .seat-chart-modal-header{
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #15124a;
    margin-top: 0px;
    border-radius: 8px 8px 0px 0px;
    padding: 0px 10px;
    line-height: 50px;
}
.seat-chart-modal-header-text{
  color: #fff;
  font-weight: 600;
  position: relative;
  top: 5px;
}
.seat-chart-modal-header-seat-chart{
  color: #fff;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    top: 7px;
}
.seat-chart-main-bed-box-main {
  width: 100%;
  min-height: 250px;
  /* background: lightgrey; */
  margin-top: 20px;
  padding:0 10px;
}
.seat-chart-main-bed-box{
display: flex;
justify-content: space-between;
flex-wrap: wrap;

}
.seat-chart-main-bed-boxes {
  height: 50px;
  width: 60px;
  background: #15124a;
  text-align: center;
  line-height: 50px;
  margin:10px 18px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  padding: 0px 5px;
  cursor: pointer;
  color: #fff;
  font-weight: 600;
}
.seat-chart-modal-footer {
  width: 100%;
  height: 50px;
  background: #15124a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  position: relative;
  top: 14px;

}
button.booking-btn {
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
}
h5.seat-chart-modal-footer-text {
  color: white;
  font-size: 18px;
  position: relative;
  top: 4px;
}
.seat-chart-main-bed-image {
  margin-top: 20px;
}
.seat-chart-modal-footer-text-price{
  color: #fff;
}
.seat-chart-main-bed-boxes.selected {
  background-color: green; /* Change background color to green when selected */
}
@media only screen and (max-width: 600px) {
  .seat-chart-modal{
    top:20;
  }
  .seat-chart-modal-content {
    width: 95%;
  }
  .seat-chart-modal {
    z-index: 99999;
  }
}