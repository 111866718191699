.bookin-left-top {
  border-radius: 4px;
  border: 1px solid rgba(245, 166, 35, 0.19);
  background-color: rgb(254, 246, 233);
  padding: 16px;
  color: rgb(211, 140, 23);
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
}
.booking-left-main-section {
  border: 1px solid rgba(122, 122, 122, 0.17);
  border-radius: 4px;
}
.left-enter-details {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 14px 24px;
  color: rgb(0, 0, 0);
  background: rgb(249, 249, 251);
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
}
.c-1kz1i96 {
  font-size: 12px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  padding: 0px 7px;
  border-radius: 4px;
  margin-right: 12px;
  background: rgb(0, 0, 0);
}
.c-i9gxme {
  -webkit-box-flex: 1;
  flex-grow: 1;
  text-align: left;
}
.booking-form {
    text-align: left;
    padding: 0px 24px 65px;
}
.booking-form-box{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
}
.c-1s0ylt3 {
    text-align: center;
    font-size: 14px;
    line-height: 1.7;
    margin: 24px 0px;
}
.c-qln3k6 {
    flex: 0 1 46%;
    box-sizing: border-box;
    margin: 14px 20px 6px 0px;
}
.textInput__container {
    position: relative;
    border-radius: 4px;
}
.textInput__inputLabel {
    margin: 0 0 9px;
    font-weight: 600;
    font-size: 16px;
}
.textInput__input:invalid, .textInput__input:required {
    box-shadow: none;
}
.textInput__input {
    color: rgba(0,0,0,.87);
    border-radius: 2px!important;
    border: 1px solid hsla(0,0%,65.1%,.2);
    padding: 12px 0 12px 16px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    margin-bottom: 12px;
}
.react-tel-input .form-control {
    height: 42px!important;
    width: 100%!important;
}
.react-tel-input .selected-flag .arrow.up{
    display: none!important;
}
.react-tel-input .selected-flag .arrow {
    display: none!important;
}
input.form-control:focus {
    box-shadow: none!important;
}
.c-1ogcbvc {
    display: flex;
    height: 100%;
    align-items: flex-end;
}
.c-8cq10 {
    border-radius: 4px;
    border: 0px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
    padding: 14px;
    width: auto;
    height: auto;
    margin-bottom: -12px;
    flex: 1 1 50%;
    margin-right: 24px;
    display: block;
    cursor: pointer;
    outline: 0px;
    color: rgb(255, 255, 255);
    background: rgb(223, 223, 223);
    margin-top: 20px;
}
@media only screen and (max-width: 600px) {
    .bookin-left-top{
        position: relative;
        left:12px;
    }
    .booking-left-main-section{
        position: relative;
        left:12px;
    }
    .c-qln3k6 {
        flex: 0 1 100%;
       
    }
  }