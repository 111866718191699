@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
button.update-profile-button {
    padding: 8px 20px;
    margin-top: 20px;
    background: #04043b;
    color: white;
    border-radius: 5px;
}
label.user-profile-labels p {
    text-align: left;
    font-weight: 600;
    padding: 2px 0px;
}
.registration-container {
    background: #fff;
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 1rem;
}

.registration-container h2 {
    font-weight: bold;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    position: relative;
    text-align: left;
}

.registration-container h2::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    height: 3px;
    width: 22%;
    border-radius: 2px;
    background-color: #e65b00;
    margin-top: 3px;
}
form.registration-form h4 {
    text-align: left;
    font-size: 1rem;
    font-weight: 600;
}
/* .three-details-item {
    display: flex;
    flex-wrap: wrap;
     width: 100%;
    justify-content: space-between;
} */

hr {
    width: 100%;
    height: 1px;
    background-color: #d2d2d2;
    margin: 1rem 0;
    border: none;
}

.personal-details,
.identity-details {
    margin: .2rem 0;
}

.personal-details label,
.identity-details label {
    margin: .5rem 0rem;
    font-size: .9rem;
    font-weight: 600;
    text-align: left;
}

.three-details-item input {
    outline: none;
    font-size: .8rem;
    border-radius: .2rem;
    border: 1px solid rgb(113, 101, 101);
    padding: .5rem!important;
    background-color: transparent;
}

.submit-btn {
    border: none;
    background-color: #e65b00;
    color: white;
    padding: .3rem 1rem;
    display: block;
    width: 50%;
    margin: .6rem auto;
    border-radius: 2rem;
    font-size: 1.1rem;
}

.three-details-item p{
    margin-bottom: 0.2rem;
}
.user-profile-input-boxes{
width:  100%!important;
}
.user-profile-labels{
display: block!important;
}