.thumbnail-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: relative;
}

.main-image {
  border: 2px solid #333;
}

.thumbnail-container {
  display: flex;
  gap: 10px;
}

.thumbnail-container img {
  width: 100px;
  border: 1px solid #ddd;
  cursor: pointer;
}

.thumbnail-container img.active {
  border: 2px solid #333;
}
button.responsive-slide-next {
  height: 32px;
  border-radius: 50%;
}
button.responsive-slide-next {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  position: absolute;
  /* z-index: 9999!important; */
  top: 180px;
  right: 0px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
button.responsive-slide-next svg,button.responsive-slide-prev svg{
font-size: 14px;
}
button.responsive-slide-prev {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  position: absolute;
  /* z-index: 9999!important; */
  top: 180px;
  left: 0px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.responsive-crousel-slider-img{
height: 400px;
width: 100%;
}
.responsive-crousel-slider-img img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media screen and (max-width: 600px) {
  .responsive-crousel-slider-img img{
   width:100%!important;
  }
  .carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden;
    position: relative;
    right: 12px;
}
.thumbnail-slider{
display: revert!important;

}
.responsive-crousel-slider-img{
  height:revert!important;
  width: 100%;
  }
  .carousel .slide {
    height: 230px!important;
  }
}