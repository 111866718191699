.header-search-bar {
  height: 70px;
  background-color: #130b9d;
  position: relative;
  top: 10px;
  width: 76%;
  padding: 5px;
  border-radius: 5px;

}
.sticky-nave-cont {
  padding: 0px;
  margin-left: 10px;
}
.sticky-login-btn {
  position: relative;
  left: 50px;
  top: 5px;
}
.nav-sticky-col {
  padding: 0px 2px;
}

input.search-input-hostel.header-search-city:focus-visible {
 
  outline: none;
}
input.search-input-hostel.header-search-city {
  width: 100%;
  height: 60px;
  padding-left: 10px;
  border: 1px solid black;
  border-radius: 2px 0px 0px 2px!important;
}
.sticky-nave-cont .react-datepicker-wrapper input.sticky-header-search-city-date:focus-visible{
outline: none;
}
input.sticky-header-search-city-date:focus-visible{
  outline: none;
}
.search-input-hostel header-search-city:focus-visible{
  outline: none;
}
.sticky-nave-cont .react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
 
  width: 100%!important;
  margin-left: 0px;
 
}


.sticky-nave-cont
  .react-datepicker-wrapper
  input.sticky-header-search-city-date {
  /* width: 235px !important; */
  width: 100%!important;
  border-radius: 0px;
  height: 60px;
  border: 1px solid black;
  padding-left: 10px;
}
form.select-form-seerch.header-search-city-select {
  border: 1px solid black;
  /* height: 100%; */
  height: 60px;
  width: 180px;
}
select.stick-header-form-select.form-select {
  height: 100%;
}
select.stick-header-form-select.form-select:focus {
  box-shadow: none;
  border: none;
}
select.stick-header-form-select.form-select:focus-visible {
  box-shadow: none;
  border: none;
  outline: none;
}
select.form-select {
  border-radius: 0px !important;
}
.search-field.sticky-details-nav {
  position: absolute;
  top: 5px;
}




/* CSS */
.sticky-header-search-btn {
  appearance: none;
  backface-visibility: hidden;
  background-color: #27ae60;
  border-radius: 2px;
  border-style: none;
  box-shadow: rgba(39, 174, 96, .15) 0 4px 9px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 13px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
 left:8px;
  height: 60px;
}

.sticky-header-search-btn:hover {
  background-color: #1e8449;
  opacity: 1;
  transform: translateY(0);
  transition-duration: .35s;
}

.sticky-header-search-btn:active {
  transform: translateY(2px);
  transition-duration: .35s;
}

.sticky-header-search-btn:hover {
  box-shadow: rgba(39, 174, 96, .2) 0 6px 12px;
}

.search-bar-mobile {
  display: none;
}
@media only screen and (max-width: 600px) {
  .header-search-bar.details-headers-sticky {
    display: none;
  }
  .search-bar-mobile {
    display: none;
    width: 100%;
    position: relative;
  }
  .mobile-search-bar-box {
    width: 100%;
    min-height: 35px;
    background: aliceblue;
    margin-top: 8px;
    position: relative;
    border: 1px solid #15124b;
    border-radius: 4px;
  }
  .mobile-search-bar-box input {
    height: 35px;
    padding: 5px;
    min-width: 88%;
    border-radius: 4px 0px 0px 4px;
    border: none;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  }
  .mobile-search-bar-box input:focus-visible {
    outline: none;
  }
  button.mobile-view-search-btn {
    padding: 8px 10px;
    border: 1px solid #15124b;
    background: #15124b;
    color: white;
    min-width: 12%;
    border-radius: 0px 4px 4px 0px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  }
  ul.mobile-menu-suggestion-box {
    position: absolute;
    background: #fff;
    width: 100%;
    text-align: left;
    /* padding: 5px 2px; */
    margin-top: 5px;
    z-index: 99;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  ul.mobile-menu-suggestion-box li {
    padding: 5px;

    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid grey;
    width: 100%;
  }
  .sticky-login-btn{
    left:0px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 767px) {
  .header-search-bar.details-headers-sticky {
    display: none;
  }
  .search-bar-mobile {
    display: block;
    width: 100%;
    position: relative;
  }
  .mobile-search-bar-box {
    width: 100%;
    min-height: 35px;
    background: aliceblue;
    margin-top: 8px;
    position: relative;
    border: 1px solid #15124b;
    border-radius: 4px;
  }
  .mobile-search-bar-box input {
    height: 35px;
    padding: 5px;
    width: 87%;
    border-radius: 4px 0px 0px 4px;
    border: none;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  }
  .mobile-search-bar-box input:focus-visible {
    outline: none;
  }
  button.mobile-view-search-btn {
    padding: 8px 10px;
    border: 1px solid;
    background: #15124b;
    color: white;
    min-width: 12%;
    border-radius: 0px 4px 4px 0px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  }
  ul.mobile-menu-suggestion-box {
    position: absolute;
    background: #fff;
    width: 100%;
    text-align: left;
    /* padding: 5px 2px; */
    margin-top: 5px;
    z-index: 99;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  ul.mobile-menu-suggestion-box li {
    padding: 5px;

    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid grey;
    width: 100%;
  }
  .sticky-login-btn{
    left:0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
  .header-search-bar.details-headers-sticky {
    display: none;
  }
  .search-bar-mobile {
    display: none;
    width: 100%;
    position: relative;
    display: none;
  }
  span.sticky-nave-brand.navbar-brand {
    width: 32% !important;
  }
  .nave-bar-home-header.sticky-home-nave-header {
    width: 28%;
  }
  .mobile-search-bar-box {
    width: 100%;
    min-height: 41px;
    border: none;
    border-radius: 4px;
  }
  .search-bar-mobile {
  
    width: 300px;
    min-height: 40px;
    position: absolute;
    left: 35%;
    border: 1px solid #15124b;
    border-radius: 2px;
    margin-top: 5px;
    /* background: greenyellow; */
  }
  .mobile-search-bar-box input {
    height: 41px;
    padding: 5px;
    width: 81%;
    border: none;
  }
  .mobile-search-bar-box input:focus-visible {
    outline: none;
  }
  button.mobile-view-search-btn {
    padding: 10px 10px;
    border: 1px solid #15124b;
    min-width: 18%;
    background: #15124b;
    color: #fff;
    min-height: 42px;
  }
  ul.mobile-menu-suggestion-box {
    position: absolute;
    background: #fff;
    width: 100%;
    text-align: left;
    /* padding: 5px 2px; */
    margin-top: 5px;

    z-index: 99;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  ul.mobile-menu-suggestion-box li {
    padding: 5px;

    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid grey;
    width: 100%;
  }
}
@media only screen and (min-width: 959px) and (max-width: 1180px) {
  .header-search-bar.details-headers-sticky {
    display: none;
  }
  .search-bar-mobile {
    display: none;
    width: 100%;
    position: relative;
  }
  .mobile-search-bar-box {
    width: 100%;
    min-height: 41px;
    border: none;
    border-radius: 4px;
  }
  .search-bar-mobile {
   
    width: 350px;
    min-height: 40px;
    position: absolute;
    left: 36%;
    border: 1px solid #15124b;
    border-radius: 2px;
    margin-top: 5px;
    /* background: greenyellow; */
  }
  .mobile-search-bar-box input {
    height: 44px;
    padding: 5px;
    width: 83%;
    border: none;
  }
  .mobile-search-bar-box input:focus-visible {
    outline: none;
  }
  button.mobile-view-search-btn {
    padding: 10px 10px;
    border: 1px solid #15124b;
    min-width: 16%;
    background: #15124b;
    color: #fff;
    min-height: 44px;
  }
  ul.mobile-menu-suggestion-box {
    position: absolute;
    background: #fff;
    width: 100%;
    text-align: left;
    /* padding: 5px 2px; */
    margin-top: 5px;

    z-index: 99;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  ul.mobile-menu-suggestion-box li {
    padding: 5px;

    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid grey;
    width: 100%;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  input.header-search-city-date {
    width: 190px !important;
    border-radius: 0px;
  }
  input.search-input-hostel.header-search-city {
    width: 280px !important;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1480px) {
  .header-search-bar {
    width: 78%;
  }
  .sticky-nave-cont {
   
    margin-left: 5px;
}
.sticky-header-search-btn {
  left:20px;
}
}