.terms-condition-logo img {
    height: 100%;
  }
  .terms-condition-logo {
    height: 85px;
  }
  .terms-condition-heading {
    height: 150px;
    width: 100%;
    padding: 20px 0px;
    background-image: url(./TermsConditionImage/refundpolicy.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .terms-condition-heading-text {
    text-align: center;
    color: white;
    font-size: 40px;
    font-weight: 600;
  }
  p.terms-condition-sub-text {
    color: white;
    font-size: 18px;
    text-align: center;
  }
  p.terms-condition-sub-text span a {
    text-decoration: none;
    color: white;
  }
  .terms-condition-text-box {
    width: 100%;
    min-height: 600px;
  text-align: justify;
    padding: 10px 0px;
  }
  .terms-condition-para-text1 {
    color: black;
    display: block;
    line-height: 24px;
    font-weight: 400 !important;
    margin: 0 0 20px;
    font-size: 16px;
    text-align: justify;
  }
  .terms-condition-box-heading{
      font-size: 26px;
      font-weight: 500;
      font-family: 'Raleway', sans-serif;
      color: #15124b;
      margin: 0px 0px 14px 0px;
      padding: 0px;
  }
  .terms-condition-para-list{
      margin-top: 10px;
      list-style: revert!important;
      margin-left: 20px;
  }
  h6.terms-condition-box-sub-heading {
    font-size: 20px;
    font-weight: 500;
    color: #15124b;
}
h6.terms-condition-box-heading.terms-refund-condition-policy {
    font-weight: 600;
}