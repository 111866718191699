.blog-details-screen{
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
}

.blog-details-screen-heading {
    height: 150px;
    width: 100%;
    padding: 20px 0px;
    background-color: #15124b;
  
  }
  .blog-details-screen-heading-text {
    text-align: center;
    color: white;
    font-size: 40px;
    font-weight: 600;
  }
  .blog-details-screen-sub-text{
    text-align: center;
    color: white;
    font-size: 40px;
    font-weight: 600;
  }
  p.blog-details-screen-sub-text {
    color: white;
    font-size: 18px;
    text-align: center;
  }
  p.blog-details-screen-sub-text span a {
    text-decoration: none;
    color: white;
  }