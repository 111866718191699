.our-mission-main {
  min-height: 450px;
  justify-content: center;
  color: #000;
  position: relative;

  display: flex;
}
.our-mission-cont {
  width: 100%;
  padding: 0 7vw;
  margin-top: 50px;
}
.our-mission-main .row {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.column-6 {
  flex: 0 0 auto;
  width: 50%;
}
.our-mission-heading-text {
  /* font-size: clamp(2rem, 0rem + 5.555555555555556vw, 5rem); */
  font-size: 40px;
  font-weight: 700;

color: #15124b;
  line-height: clamp(2.7rem, 0rem + 5.755555555555556vw, 5.2rem);
}
.our-mission-discrtption {
  font-size: 18px;
  line-height: 22px;
  max-width: 360px;
  color: #15124b;
  margin-top: 32px;
  font-weight: 400;
  text-align: justify;
}
.our-mission-banner-img {
  position: absolute;
  top: 0px;
  right: 0;
  height: 450px;
}
.our-mission-banner-img img {
  width: 50vw;
}
@media screen and (max-width: 600px) {
  .column-6 {
    width: 100%;
  }
  .our-mission-banner-img {
    position: relative;
    height: revert;
  }
  .our-mission-banner-img img {
    width: 100%;
  }

}
