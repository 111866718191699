.my-booking-money-card {
  width: 378px;
  border-radius: 2px;
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d6d6d6;
  margin-right: 24px;
}
.my-booking-money-card-box {
  padding: 34px 24px;
}
.c-17anid6-icon-money {
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;

  border-radius: 50%;
  width: 42px;
  height: 42px;
  float: left;
}
.c-17anid6-icon-money img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.logo-money {
  width: 100%;
  display: flex;
}
.my-booking-money-heading {
  display: flex;
}
.c-nfd45z-text-money {
  margin: 10px 0px 12px;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.23;
  color: rgb(51, 51, 51);
}
.c-qu0bs3 {
  position: relative;
  display: flex;
}
.c-qu0bs3 .infoStateIconClass {
  display: inline-block;

  margin: 10px 0px 12px 10px;
}
.infoStateIconClass .tooltiptext-money {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
.infoStateIconClass.tooltiptext-money::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.infoStateIconClass .tooltiptext-money :hover .tooltiptext-money {
  visibility: visible;
  opacity: 1;
}
.styles-module_dark__xNqje {
  background: white !important;
  color: black !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}
.infoStateIconData {
  width: 344px !important;
  border-radius: 1px;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 4px 6px 2px,
    rgba(0, 0, 0, 0.1) 0px 12px 11px 2px;
  right: -175px;
  top: 50px;
  position: absolute;
  text-align: justify;
}
.hostel-money-tooltip-paragraph{
    font-weight: 500;
}
.mybooking-money-containt-box > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 1.56;
    color: rgb(34, 34, 34);
    text-align: left;
}
.c-d62dg3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 1.56;
    color: rgb(34, 34, 34);
}