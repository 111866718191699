/* .booking-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 60;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #fff;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #000;
  text-align: left;
} */
.bokking-screen{
  width: 100%;
  overflow: hidden;
}
.booking-screen-main-section {
  position: relative;
  height: auto;
  background-color: rgb(255, 255, 255);
  min-width: 100vw;
  /* padding: 65px 0px; */
  padding-bottom: 65px;
}
.booking-screen-container-box {
  display: flex;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.booking-modify-section {
  width: 100%;
  margin: 0px auto;
}
.c-1vrj4pl {
  font-size: 16px;
  font-weight: 600;
  color: rgb(238, 42, 36);
  width: 1130px;
  margin: 52px auto 28px;
  text-align: -webkit-left;
}
.c-1aivkbe {
  margin-right: 5px;
  vertical-align: text-bottom;
  outline: 0px;
  pointer-events: auto;
}
.c-1aivkbe svg {
  fill: rgb(238, 42, 36);
  height: 16px;
}
.c-isphd1 {
  outline: 0px;
  pointer-events: auto;
}
.book-now-section {
    width: 100%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
}
.book-left-box {
    width: 648px;
    border-radius: 4px;
    margin-right: 24px;
}
.booking-screen{
    width: 100%;
    overflow: hidden;
}
@media only screen and (max-width: 600px) {
.booking-right-section-container{
position: relative!important;
top:24px
}
.c-1vrj4pl {
  margin:0px;
}
}