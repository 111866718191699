.enquiry-form-div {
    /* background: yellowgreen; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    padding: 15px;
    background-color: #ffffff;
}
.enquiry-form-div select{
border-radius: 5px!important;
}
h6.enq-heading {
    font-size: 26px;
    font-weight: 600;
    /* border-bottom: 1px solid rgb(227 219 219);;
    padding-bottom: 20px; */
}
label.enquiry-label {
    font-size: 16px;
    font-weight: 500;
    text-align: left!Important;
    width: 100%;
    margin: 5px 0px;
}
.enq-input{
min-height: 45px!important;
}
.enq-input:focus{
    outline: none;
    box-shadow: none;
}
.error-msg {
    font-size: 16px;
    text-align: left;
    margin: 5px 0px;
    color: red;
    font-weight: 500;
}
input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .btn-enquiry{
    background-color:#15124b ;
    color: white;
    width: 100%;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    min-height: 40px;
  }
  .btn-enquiry:active{
    background-color:#15124b ;
  }
  .btn-enquiry:hover{
    background-color:#15124b ;
    color: white;
  }
  .enq-heading-box {
    width: 100%;
    display: flex;
    height: 50px;
    background: #15124b;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px 8px 0px 0px;
    color: white;
    padding: 0px 5px;
    position: relative;
}

.btn-whatsapp-pulse {
	background: #25d366;
	color: white;
	position: absolute;
	/* /* bottom: 20px; */
	right: 10px; 
	font-size: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 0;
	height: 0;
	padding: 20px;
	text-decoration: none;
	border-radius: 50%;
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
	}
	80% {
		box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
	}
}

.btn-whatsapp-pulse-border {
	/* bottom: 120px;
	right: 20px; */
	animation-play-state: paused;
}
i.fab.fa-whatsapp svg {
  position: relative;
  bottom: 5px;
  font-size: 30px;
}

.btn-whatsapp-pulse-border::before {
	content: "";
	position: absolute;
	border-radius: 50%;
	padding: 15px;
	border: 5px solid #25d366;
	opacity: 0.75;
	animation-name: pulse-border;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

@keyframes pulse-border {
	0% {
		padding: 20px;
		opacity: 0.75;
	}
	75% {
		padding: 25px;
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
a.btn-whatsapp-pulse.btn-whatsapp-pulse-border:focus-visible {
  outline: none;
}