.booking-history {
    padding: 32px 24px;
    min-height: 210px;
    margin: 38px 0px 0px;
    -webkit-box-pack: center;
    justify-content: center;
    width: 1180px !important;
}
.c-it9yxr-text {
    width: 378px;
    border-radius: 2px;
    box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.1);
    border: solid 1px #d6d6d6;
    margin-right: 24px;
}
.booking-history-c-dnffwc {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px 75px;
    flex-wrap: wrap;
}

.booking-history-heading {
    font-size: 26px;
    line-height: 32px;
    font-weight: bold;
    margin: 0px;
}
 .booking-history-c-dnffwc >select  { background-color: rgb(255, 255, 255);
    font-size: 16px;
    line-height: 32px;
    height: 40px;
    font-weight: normal;
    text-indent: 10px;
    border-radius: 2px;
    border: 1px solid rgba(166, 166, 166, 0.2);
    min-width: 200px;
}
.booking-history-c-dnffwc >select:focus-visible{
    outline: none;
}
.c-ev22md-error-text {
    font-size: 20px;
    font-weight: 600;
    color: rgb(238, 46, 36);
    height: 100%;
    width: 100%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 50px 0px;
}