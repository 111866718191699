ul.pagination {
    margin-top: 50px;
    /* background: dimgrey; */
    padding: 6px 0px;
}
button.pagignation-prev-btn {
    padding: 3px 8px;
    border: none;
    background: #15124b;
    color: #fff;
}
button.pagignation-next-btn {
    padding: 3px 8px;
    border: none;
    background: #15124b;
    color: #fff;
}
button.pagignation-page-btn {
    margin: 0px 6px;
    border: none;
    padding: 3px 8px;
}