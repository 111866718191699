.common-container {
  padding: 30px;
  background: #f9f9f9;
}
.common-container .container-fluid {
  /* background: #fff; */
  margin-top: 10px;
  /* box-shadow: 0 0 1px rgba(0, 0, 0, 0.13), 0 1px 3px rgba(0, 0, 0, 0.2); */
  border-radius: 5px;
  min-height: 120px;
}
.carousel .thumb img {
  vertical-align: top;
  height: 50px!important;
}
/* .package-left-area,
.package-right-area {
  height: 700px;
  overflow-y: scroll;
  overflow-x: hidden;
} */
.package-details-page .common-container .container-fluid .package-left-area
/* .package-details-page .common-container .container-fluid .package-right-area  */
{
  padding-left: 5px;
  padding-right: 5px;
}

.productLeft_heading {
  /* background: hsla(0, 0%, 97.3%, 0.5215686274509804); */
  border-left: 4px solid #ffab02;
  border-radius: 10px 0 0 10px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.18);
  min-height: 100px;
  background-color: #ffffff;
}

.productLeft_heading h5 {
  padding-top: 5px;
  padding-left: 15px;
  font-size: 14px;
  margin-bottom: 5px;
  text-align: left;
}
.package-details-page .productLeft_heading h5 span {
  color: #ffab02;
}
.productLeft_heading h3 {
  color: #15124b;
  font-size: 24px;
  margin-left: 15px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  padding-top: 15px;
}
.hodtel-detail-page-review {
  height: auto;
  text-align: center;
  position: relative;
  cursor: pointer;
  min-width: 80px;
  margin-top: 14px;
  float: right;
  right: 15px;
}
.hostel-details-review-tech {
  background: #15124b;
  color: #fff;
  font-size: 20px;
  font-weight: bolder;
  width: 100%;
  height: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding: 8px 0;
}
.hostel-details-review-tech-star {
  height: 15px;
  width: 15px;
}
.hostel-details-review-tech-star svg {
  fill: #fff;
}
.hostel-details-review-tech-star svg {
  position: relative;
  /* top: 0px; */
  left: 5px;
  bottom: 2px;
}
.hostel-details-review-text {
  font-size: 14px;
  font-weight: 400;
  padding: 4px;
  text-align: center;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  word-break: break-word;
  white-space: nowrap;
  background: #f4f4f4;
}
/* .package-left-area{
height: 900px;
overflow-y: scroll;
overflow-x: hidden;

padding-left: 5px;
  padding-right: 5px;
}
.package-right-area{
  height: 900px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 5px;
  padding-right: 5px;
 
} */
.package-left-area::-webkit-scrollbar {
  width: 2px;
}
.package-right-area::-webkit-scrollbar {
  width: 2px;
}
.package-details-page .common-card {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0 !important;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.05);
}
.product_left_para {
  width: 100%;
  margin: auto;
  padding: 5px;
  margin-top: 30px !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  background-color: #ffffff;
}
.package-details-page .common-card {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0 !important;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.05);
}
.product_left_details {
  width: 100%;
  margin: 30px 0;
  /* box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05); */
  box-shadow: 0 1px 8px rgba(0,0,0,.18);
  padding-right: 14%;
  padding-bottom: 9px;
  border-radius: 2px;
  background-color: #fff;
}

.product_left_details h4 {
  font-size: 21px;
  color: #15124b;
  font-weight: 700;
  margin: 15px 0;
  width: 100%;
  text-align: left;
  padding: 15px 14px 12px 10px;
  border-bottom: 1.5px solid hsla(0, 0%, 88.6%, 0.4);
}
.product_left_details_box {
  display: flex;
  padding: 10px;
}
.product_left_details_box h6 {
  font-size: 14px;
  text-align: left;
  font-weight: 490;
}

ul.hostel-amenties-details-list {
  display: flex;
  width: 100%;
  text-align: left;
  font-size: 15px;
  flex-wrap: wrap;

  font-weight: 400;
}
ul.hostel-amenties-details-list li {
  width: 25%;
}

ul.hostel-amenties-details-list li::before {
  content: "\2713";
  display: inline-block;
  color: green;
  font-weight: 600;
  padding: 0 6px 0 0;
}
h6.hostel-details-membership {
  text-align: left;
  margin-left: 15px;
  background: #15124b;
  width: 154px;
  padding: 5px;
  color: white;
  border-radius: 5px;
}

.navbar-toggle .icon-bar {
  background: #000;
}
.navbar-toggle {
  border: 1px solid #000;
}
.navbar-collapse {
  border-top-color: transparent;
}
ul.nearme-details-nave {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  top: -15px;
  background: #fbfbfc;
}
ul.nearme-details-nave li {
  padding: 8px;
  margin: 0px 10px;
  cursor: pointer;
  font-weight: 500;
  color: #15124b;
}
ul.nearme-details-nave li.active {
  background: #15124b;
  color: white;
}
.near-by-common {
  flex-wrap: wrap;
}
.near-by-section-1-content-box {
  width: 100%;
}
.near-by-section-1-content {
  width: 60%;
}
li.near-by-section-1-content-li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #15124b;
  font-weight: 490;
    font-size: 16px;
  align-items: center;
}
span.near-by-section-star svg {
  font-size: 18px;
  position: relative;
  /* top: -1px; */
  bottom: 2px;
  margin-right: 2px;
}
.faq-block {
  position: relative;
  top: 0px;
}
.faq-block h4 {
  margin: 0px;
  font-size: 18px;
  padding: 0px;
  border: none;
  font-weight: 500;
}
.faq-block p {
  font-size: 16px;
  padding-left: 10px;
}
.faq-block p span{
  font-weight: 500;
}
h6.policy-cancel-payment {
  font-size: 18px;
  font-weight: 500;
  position: relative;
  top: -15px;
  width: 100%;
}
.details-policy{
  display: flex;
flex-wrap: wrap!important;
}
.policy-circle-icon svg{
  font-size: 20px;
  color: #15124b;
  margin-right: 5px;
}
.reactplayer {
  width: 100%!important;
}
ul.hostel-policy-details {
  text-align: left;
  position: relative;
    top: -40px;
}
button.read-more-read-less-btn {
  border: none;
  background:none;
  font-size: 14px;
  font-weight: 600;
  color: 15124b;
  padding:10px 5px;
  border-radius: 5px;
}
button.read-more-read-less-btn:hover {
  border: none;
  background: #15124b;
  font-size: 14px;
  font-weight: 600;
  color: white;
  padding:10px 5px;
  border-radius: 5px;
}
@media screen and (max-width: 600px) {
  .product_left_details{
    padding-right: 0%;
    overflow: hidden;
  }
  ul.hostel-amenties-details-list li{
    width: 50%;
  }
  ul.nearme-details-nave {
    flex-wrap: wrap;
    
        list-style: none;
  }
  ul.nearme-details-nave li {
    margin:5px 10px;
    background-color: grey;
  }
  .near-by-section-1-content {
    width: 100%;
}
}