.item {
  position: relative;
  z-index: 100;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.owl-nav button {
  position: absolute;
  top: 50%;
  background-color: #000;
  color: #fff;
  margin: 0;
  transition: all 0.3s ease-in-out;
}
.owl-nav button.owl-prev {
  left: 0;
}
.owl-nav button.owl-next {
  right: 0;
}

.owl-dots {
  text-align: center;
  /* padding-top: 15px; */
  display: none;
}
.owl-dots button.owl-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  background: #ccc;
  margin: 0 3px;
}
.owl-dots button.owl-dot.active {
  background-color: #000;
}
.owl-dots button.owl-dot:focus {
  outline: none;
}
.owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.38) !important;
}
.Hostel-details-slider button.owl-next span {
 font-size: 70px;
  position: relative;
  top: -2px!important;
}
.Hostel-details-slider button.owl-prev span{
    font-size: 70px;
  position: relative;
  top: -2px!important;
}
.Hostel-details-slider .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  line-height: 40px;
} 
.owl-nav button:focus {
  outline: none;
}
.hostel-details-img-slid {
  height: 350px;
}
.hostel-details-img-slid img {
height: 100%;
width: 100%;
object-fit: cover;
}
.Hostel-details-slider{
  width: 100%;
  overflow: hidden;
}
@media only screen and (max-width: 600px) {

  .Hostel-details-slider {
   
    width: 98%!important;
    overflow: hidden;
    margin: auto;
    margin-top: 8px!important;
}
}