.popular-cont {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
._category-page-all-hostels-section {
  margin: 10px 5px 40px;
  background-color: #fff;
  padding: 20px;
}
._block-header {
  border-bottom: 1px solid #e7eff9;
  margin-bottom: 20px;
}
.category-page-list-heading {
  font-size: 1.5rem;
  color: #15124b;
  margin-top: 0;
  text-align: left;
  font-weight: 600;
}
.infinite-scroll-component {
  height: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.category-page-single-product-card {
  cursor: pointer;
  position: relative;
  width: 95%;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  border-radius: 10px;
  /* overflow: hidden; */
}
.material-shadow-card-light {
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.14);
}
._category-page-all-hostels-section button.owl-prev span {
 position: relative!important;
 top: -10px!important;
}
._category-page-all-hostels-section button.owl-next span{
  position: relative!important;
  top:0px!important;
}
._category-page-all-hostels-section owl-item{
  width: 30px!important;
}
@media only screen and (max-width: 767px) {
  ul.facility-text li a {
    left:3px;
  }
  .hostel-bed-avility-details ul li img {
    width: 16px !important;
}
button.owl-prev {
  height: 30px;
  width: 30px;
}
button.owl-prev span {
  font-size: 32px !important;
 
}
button.owl-next {
  height: 30px!important;
  width: 30px!important;
}
button.owl-next span {
  font-size: 32px !important;
 position: relative!important;
 left:2px;
 top:-2px;
}
}